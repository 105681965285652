<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <label>Nome completo</label>
                  <v-select
                    :options="pacientes"
                    :label="'nome'"
                    v-model="paciente_selecionado"
                    @input="selectPaciente"
                    class="vselect" disabled
                  ></v-select>
                </div>
                <div class="col-md-4">
                  <label>CPF</label>
                  <input
                    type="text"
                    class="form-control"
                    v-mask="'999.999.999-99'"
                    v-model="agendamento.cpf"
                    disabled
                  />
                </div>
                <div class="col-md-4">
                  <label>Cartão SUS</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="agendamento.cartao_sus"
                    disabled
                  />
                </div>

                <div class="col-md-4">
                  <label>Cartão convênio</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="agendamento.cartao_convenio"
                    disabled
                  />
                </div>

                <div class="col-md-4">
                  <label>Especialidade</label>
                  <v-select
                    :options="especialidades"
                    :reduce="(especialidades) => especialidades.id"
                    :label="'nome'"
                    v-model="agendamento.especialidade_id"
                    class="vselect"
                    @input="getEspecialidadeProfissional"
                  ></v-select>
                </div>

                <div class="col-md-4">
                  <label>Profissional</label>
                  <v-select
                    :options="profissionais"
                    :reduce="(profissionais) => profissionais.id"
                    :label="'nome'"
                    v-model="agendamento.profissional_id"
                    class="vselect"
                  ></v-select>
                </div>

                <div class="col-md-2">
                  <label>Data</label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="agendamento.data_agendada"
                  />
                </div>
                <div class="col-md-2">
                  <label>Horário</label>
                  <input
                    type="time"
                    class="form-control"
                    v-model="agendamento.horario"
                  />
                </div>

                <div class="col-md-6">
                  <label>Status</label>
                  <select class="form-control" v-model="agendamento.status">
                    <option class="badge badge-info" value="0">Aguardando</option>
                    <option class="badge badge-secondary" value="1">Agendado</option>
                    <option class="badge badge-warning" value="2">Ausente</option>
                    <option class="badge badge-danger" value="3">Cancelado</option>
                    <option class="badge badge-success" value="4">Confirmado</option>
                  </select>
                </div>
                <div class="col-md-12 text-right">
                  <hr />
                  <router-link to="/agendamentos" class="btn btn-back mr-1">
                    <i class="fa fa-arrow-left"></i> Voltar
                  </router-link>
                  <a class="btn btn-add" @click="save">
                    Salvar
                    <i class="fa fa-save"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      agendamento: {
        cpf: "",
        cartao_sus: "",
        cartao_convenio: "",
        especialidade_id: "",
        profissional_id: "",
        data_agendada: "",
        horario: "",
        status: "",
      },
      pacientes: [],
      especialidades: [],
      paciente_selecionado: {},
      empresas: [],
      profissionais: [],
    };
  },
  computed: {},
  methods: {
    selectPaciente() {
      this.agendamento.cpf = this.paciente_selecionado.cpf;
      this.agendamento.cartao_sus = this.paciente_selecionado.cartao_sus;
      this.agendamento.cartao_convenio =
        this.paciente_selecionado.cartao_convenio;
      this.agendamento.paciente_id = this.paciente_selecionado.id;
    },
    save: function () {
      const self = this;
      let api = self.$store.state.api + "agendamentos";

      if (self.agendamento.id) {
        api += "/" + self.agendamento.id;
        self.agendamento._method = "PUT";
      }

      axios
        .post(api, self.agendamento)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/agendamentos");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getAgendamentos: function (id) {
      const self = this;
      const api =
        self.$store.state.api + "agendamentos/" + id + "?with[]=paciente";

      axios
        .get(api)
        .then((response) => {
          self.agendamento = response.data.data[0];
          self.paciente_selecionado = self.agendamento.paciente;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getPacientes: function () {
      const self = this;
      const api = self.$store.state.api + "pacientes/";

      axios
        .get(api)
        .then((response) => {
          self.pacientes = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getEspecialidades: function () {
      const self = this;
      const api = self.$store.state.api + "especialidades/";

      axios
        .get(api)
        .then((response) => {
          self.especialidades = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEmpresas: function () {
      const self = this;
      const api = self.$store.state.api + "empresas/";

      axios
        .get(api)
        .then((response) => {
          self.empresas = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEspecialidadeProfissional: function () {
      const self = this;
      const api = self.$store.state.api + "profissionais?especialidade_id=" + self.agendamento.especialidade_id;

      axios
        .get(api)
        .then((response) => {
          self.profissionais = response.data.data;
          self.agendamento.profissional_id = "";
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getProfissionais: function () {
      const self = this;
      const api = self.$store.state.api + "profissionais/";

      axios
        .get(api)
        .then((response) => {
          self.profissionais = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },

  mounted: function () {
    const self = this;
    self.getPacientes();
    self.getEspecialidades();
    self.getEmpresas();
    self.getProfissionais();

    let id = self.$route.params.id;
    if (id) {
      self.getAgendamentos(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>