<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'prontuariosCrud'"
          crudName="Lista de prontuários"
          newText="Novo Paciente"
          table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="true"
          :enableEdit="false"
          :enableDelete="false"
          :enableView="false"
          :enableReset="false"
          :dataForm="dataForm"
          :extra="extra"
          @view="view"
        >
        </base-crud>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      table: "pacientesTable",
      showMessageImport: false,
      usersCrud: {},
      pages: [],
      url: "",
      columns: ["id", "nome_completo", "cpf", , "actions"],
      tableData: ["id", "nome_completo", "cpf"],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: ["nome_completo", "cpf"],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          nome: "Nome completo",
          cpf: "CPF",
          actions: "Ações",
        },
        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {},
        templates: {},
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = [];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      extra: [
        {
          id: 1,
          label: "",
          class: "ml-1 btn btn-crud view",
          title: "Visualizar",
          name: "view",
          icon: "fas fa-eye",
        },
      ],
      endPoint: "pacientes/",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    view(props, row, index) {
      const self = this;
      this.$router.push("/prontuarios/view/" + props.row.id);
    },
    query: function (query) {
      let columns = {
        id: "id",
        nome: "nome",
        cpf: "cpf",
      };
      let filters = "";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
  },
};
</script>
<style scoped>
.VuePagination {
  text-align: center;
}
.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>