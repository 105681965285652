import Crud from '../views/Medicamento/Crud'
import Entity from '../views/Medicamento/Add'

let routes = {
    path: '/medicamentos',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/medicamentos/add',
            name: 'NewMedicamento',
            component: Entity,
            meta: {
                pageTitle: 'Medicamentos',
                breadcrumb: [
                    {
                        text: 'Medicamentos',
                        to: '/medicamentos',
                        active: false,
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/medicamentos/edit/:id?',
            name: 'EditMedicamento',
            component: Entity,
            meta: {
                pageTitle: 'Medicamentos',
                breadcrumb: [
                    {
                        text: 'Medicamentos',
                        to: '/medicamentos',
                        active: false,
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/medicamentos',
            name: 'list-medicamentos',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Medicamentos',
                breadcrumb: [
                    {
                        text: 'Medicamentos',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
