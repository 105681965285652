<template>
    <div>
        <div class="main-content">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <label><span class="required">*</span> Nome</label>
                                    <input type="text" class="form-control" v-model="principio_ativo.nome" />
                                </div>
                                <div class="col-md-12 text-right">
                                    <hr>
                                    <router-link to="/principios_ativos" class="btn btn-back mr-1">
                                    <i class="fa fa-arrow-left"></i> Voltar
                                    </router-link>
                                    <a class="btn btn-add" @click="save">
                                    Salvar
                                    <i class="fa fa-save"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      principio_ativo: {
        nome: "",
      },
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "principios_ativos";

      if(self.principio_ativo.id){
        api += '/' + self.principio_ativo.id;
        self.principio_ativo._method = 'PUT';
      }

      axios
        .post(api, self.principio_ativo)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/principios_ativos");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getPrincipioAtivo: function (id) {
      const self = this;
      const api = self.$store.state.api + "principios_ativos/" + id;

      axios
        .get(api)
        .then((response) => {
          self.principio_ativo = response.data.data[0];
          
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;

    let id = self.$route.params.id;
    if (id) {
      self.getPrincipioAtivo(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>