<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <label><span class="required">*</span> Nome do medicamento</label>
                  <input type="text" class="form-control" v-model="medicamento.nome" />
                </div>
                <div class="col-md-5">
                  <label><span class="required">*</span> Principios ativos</label>
                  <v-select :options="principios_ativos" :reduce="principios_ativos => principios_ativos.id" :label="'nome'"
                    v-model="medicamento.principio_ativo_id" class="vselect"></v-select>
                </div>
                <div class="col-md-3">
                  <label><span class="required">*</span> Dosagem</label>
                  <input type="text" class="form-control" v-model="medicamento.dosagem" />
                </div>
                <div class="col-md-6">
                  <label><span class="required">*</span> Tipo de receita</label>
                  <v-select :options="tipo_receitas" :reduce="tipo_receitas => tipo_receitas.id" :label="'label_tipo_receita'"
                    v-model="medicamento.tipo_receita_id" class="vselect"></v-select>
                </div>
                <div class="col-md-6">
                  <label><span class="required">*</span> Empresa</label>
                  <v-select :options="empresas" :reduce="empresas => empresas.id" :label="'nome_fantasia'"
                    v-model="medicamento.empresa_id" class="vselect"></v-select>
                </div>
                <div class="col-md-12">
                  <label><span class="required">*</span> Descrição</label>
                  <textarea cols="30" rows="10" class="form-control" v-model="medicamento.descricao"></textarea>
                </div>
                <div class="col-md-12 text-right">
                  <hr>
                  <router-link to="/medicamentos" class="btn btn-back mr-1">
                    <i class="fa fa-arrow-left"></i> Voltar
                  </router-link>
                  <a class="btn btn-add" @click="save">
                    Salvar
                    <i class="fa fa-save"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      medicamento: {
        nome: "",
        principio_ativo_id: "",
        dosagem: "",
        tipo_receita_id: "",
        empresa_id: "",
        descricao: "",
      },
      empresas: [],
      tipo_receitas: [],
    };
  },
  computed: {},
  methods: {


    save: function () {
      const self = this;
      let api = self.$store.state.api + "medicamentos";

      if (self.medicamento.id) {
        api += '/' + self.medicamento.id;
        self.medicamento._method = 'PUT';
      }

      axios
        .post(api, self.medicamento)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/medicamentos");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEspecialidades: function (id) {
      const self = this;
      const api = self.$store.state.api + "medicamentos/" + id;

      axios
        .get(api)
        .then((response) => {
          self.medicamento = response.data.data[0];

        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEmpresas: function () {
      const self = this;
      const api = self.$store.state.api + "empresas/";

      axios
        .get(api)
        .then((response) => {
          self.empresas = response.data.data;

        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getPrincipiosAtivos: function () {
      const self = this;
      const api = self.$store.state.api + "principios_ativos/";

      axios
        .get(api)
        .then((response) => {
          self.principios_ativos = response.data.data;

        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTipoReceitas: function () {
      const self = this;
      const api = self.$store.state.api + "tipo_receitas/";

      axios
        .get(api)
        .then((response) => {
          self.tipo_receitas = response.data.data;

        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    self.getEmpresas();
    self.getPrincipiosAtivos();
    self.getTipoReceitas();

    let id = self.$route.params.id;
    if (id) {
      self.getEspecialidades(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>