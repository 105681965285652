import Crud from '../views/Checklist/Crud'
import Entity from '../views/Checklist/Add'


let routes = {
    path: '/checklists',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/checklists/add',
            name: 'NewChecklist',
            component: Entity,
            meta: {
                pageTitle: 'checklists',
                breadcrumb: [
                    {
                        text: 'checklists',
                        to: '/checklists',
                        active: false,
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/checklists/edit/:id?',
            name: 'EditChecklist',
            component: Entity,
            meta: {
                pageTitle: 'checklists',
                breadcrumb: [
                    {
                        text: 'checklists',
                        to: '/checklists',
                        active: false,
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },

        {
            path: '/checklists',
            name: 'list-checklists',
            component: Crud,
            meta: {
                pageTitle: 'Lista de checklists',
                breadcrumb: [
                    {
                        text: 'checklists',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
