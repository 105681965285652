<template>
  <div>
    <div class="container-fluid">
      <div class="card p-2">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-primary mt-1 mb-1"
            style="padding: 8px"
            data-toggle="modal"
            data-target="#modalAgendamento"
            @click="clearEvento"
          >
            Agendamento
          </button>
        </div>
        <div class="col-md-12">
          <FullCalendar v-if="loaded" :options="calendarOptions" />
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalAgendamento"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAgendamento"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Agendamento</h5>
            <button type="button" class="close" data-dismiss="modal">
              <span>&times;</span>
            </button>
          </div>
          <div class="col-md-12">
              <label>Especialidade</label>
              <v-select
                :options="especialidades"
                :reduce="(especialidades) => especialidades.id"
                :label="'nome'"
                v-model="especialidade_id"
                class="vselect"
                @input="getEspecialidadeProfissional"
              ></v-select>
            </div>
            <div class="col-md-12">
              <label>Profissional</label>
              <v-select
                :options="profissionais"
                :reduce="(profissionais) => profissionais.id"
                :label="'nome'"
                v-model="profissional_id"
                class="vselect"
              ></v-select>
            </div>
            <div class="col-md-12">
              <label>Paciente</label>
              <v-select
                :options="pacientes"
                :reduce="(pacientes) => pacientes.id"
                :label="'nome'"
                v-model="paciente_id"
                class="vselect"
              ></v-select>
            </div>
          <div class="modal-body row">
            <div class="col-md-6">
              <label>Data</label>
              <input
                type="date"
                class="form-control"
                v-model="agenda.data_inicio"
              />
            </div>
            <div class="col-md-6">
              <label>Hora</label>
              <input
                type="time"
                class="form-control"
                v-model="agenda.hora_inicio"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="gravaEvento"
            >
              Agendar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ptLocale from "@fullcalendar/core/locales/pt-br";
import vSelect from "vue-select";

export default {
  data() {
    return {
      agenda: [],
      paciente_id: "",
      profissional_id: "",
      especialidade_id: "",
      empresa_id: "",
      agendamento: {},
      pacientes: [],
      profissionais: [],
      especialidades: [],
      empresas: [],

      mode: "",
      fullscreen: false,
      loaded: false,
      changes: false,
      fullPage: true,
      isLoading: true,
      resource_selected: "",
      originalEvents: [],
      infoModal: "",
      tes: {},
      new_demand: "",
      date_formated: "",
      calendarOptions: {
        locale: ptLocale,
        eventTimeFormat: {
          hour: "numeric",
          minute: "2-digit",
          meridiem: false,
        },

        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "timeGridWeek",
        height: "500px",
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: false,
        eventClick: this.eventoClicado,
        select: this.selecionaData,
        eventDrop: this.arrastaEvento,
        events: [],
        slotMinTime: "09:00:00",
        slotMaxTime: "18:01:00",
      },
      filters: {
        show_finished: 0,
      },
      customers: [],
      order_types: [],
      users: [],
      projects: [],
      calendarApi: "",
    };
  },
  components: {
    vSelect,
    FullCalendar,
  },
  methods: {
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      fd.append("data_agendada", self.agenda.data_inicio);
      fd.append("horario", self.agenda.hora_inicio);
      fd.append("paciente_id", self.paciente_id);
      fd.append("profissional_id", self.profissional_id);
      fd.append("especialidade_id", self.especialidade_id);
      fd.append("status", self.agendamento.status);

      fd.append("_method", "POST");

      if (self.agenda.id) {
        fd.append("id", self.agenda.id);
        fd.append("_method", "PUT");
      }

      return fd;
    },
    eventoClicado: function (clickInfo) {
      const self = this;
      const api = self.$store.state.api + "agendamentos/" + clickInfo.event.id;

      self.clearEvento();

      axios
        .get(api)
        .then((response) => {
          let dados = response.data.data[0];

          self.agenda.id = dados.id;
          self.agenda.data_inicio = dados.data_inicio;
          self.agenda.hora_inicio = dados.horario;
          self.agenda.cpf = dados.cpf;
          self.agenda.cartao_convenio = dados.cartao_convenio;
          self.agenda.cartao_sus = dados.cartao_sus;
          self.agenda.push();

          self.paciente_id = dados.paciente_id;
          self.profissional_id = dados.profissional_id;
          self.especialidade_id = dados.especialidade_id;
          self.empresa_id = dados.company_id;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });

      $("#modalEvento").modal("toggle");
    },
    selecionaData: function (selectInfo) {
      const self = this;
      self.clearEvento();

      self.agenda.data_inicio = selectInfo.startStr.split("T")[0];
      self.agenda.hora_inicio = selectInfo.startStr.split("T")[1].split("-")[0];

      $("#modalEvento").modal("toggle");
    },
    arrastaEvento: function (selectEvent) {
      const self = this;
      let fd = new FormData();

      let api = self.$store.state.api + "agendamentos/" + selectEvent.event.id;

      fd.append("data_agendada", selectEvent.event.startStr.split("T")[0]);
      fd.append(
        "horario",
        selectEvent.event.startStr.split("T")[1].split("-")[0]
      );
      fd.append("_method", "PUT");

      axios
        .post(api, fd)
        .then((response) => {})
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    gravaEvento: function () {
      const self = this;
      let api = self.$store.state.api + "agendamentos";
      let fd = self.makeFormData();
      self.loaded = false;

      if (self.agenda.id) {
        api += "/" + self.agenda.id;
      }

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );

          this.getEventos();

          setTimeout(() => {
            self.loaded = true;
          }, 1000);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    clearEvento: function () {
      const self = this;

      self.agenda.id = "";
      self.agenda.data_inicio = "";
      self.agenda.hora_inicio = "";
      self.agenda.cpf = "";
      self.agenda.cartao_convenio = "";
      self.agenda.cartao_sus = "";

      self.agenda.push();

      self.paciente_id = "";
      self.profissional_id = "";
      self.especialidade_id = "";
      self.empresa_id = "";
    },
    getEmpresas: function () {
      const self = this;
      const api = self.$store.state.api + "empresas/";

      axios
        .get(api)
        .then((response) => {
          self.empresas = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEspecialidades: function () {
      const self = this;
      const api = self.$store.state.api + "especialidades/";

      axios
        .get(api)
        .then((response) => {
          self.especialidades = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEventos() {
      const self = this;
      const api = self.$store.state.api + "agendamentos?profissional_id=" + self.$store.state.user.profissional_id;

      axios
        .get(api)
        .then((response) => {
          self.calendarOptions.events = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getPacientes: function () {
      const self = this;
      const api = self.$store.state.api + "pacientes/";

      axios
        .get(api)
        .then((response) => {
          self.pacientes = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEspecialidadeProfissional: function () {
      const self = this;
      const api = self.$store.state.api + "profissionais?especialidade_id=" + self.especialidade_id;

      axios
        .get(api)
        .then((response) => {
          self.profissionais = response.data.data;
          self.profissional_id = "";
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;

    self.getEmpresas();
    self.getEspecialidades();
    self.getEventos();
    self.getPacientes();

    setTimeout(() => {
      self.loaded = true;
    }, 500);
  },
};
</script>
<style scoped>
.VuePagination {
  text-align: center;
}

.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>