<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <label>Nome completo</label>
                  <v-select :options="pacientes" :reduce="pacientes => pacientes.id" :label="'nome'"
                    v-model="prescricoes.paciente_id" class="vselect"></v-select>
                </div>
                <div class="col-md-6">
                  <label>Tipo de receita</label>
                  <v-select :options="tipo_receitas" :reduce="tipo_receitas => tipo_receitas.id"
                    :label="'label_tipo_receita'" v-model="prescricoes.tipo_receita_id" class="vselect"></v-select>
                </div>
                <div class="col-md-12 mt-1" v-for="i in i">
                  <label>Medicamento {{ i }}:</label>
                  <v-select :options="medicamentos" :reduce="medicamentos => medicamentos.id" :label="'nome'"
                    v-model="prescricoes.medicamento" class="vselect"></v-select>
                </div>
                <div class="col-md-12 mt-1">
                  <button class="btn btn-primary" @click="i++">Adicionar medicamento</button>
                  <button class="btn btn-delete ml-1" style="height:100%" v-if="i > 1" @click="i--">Remover
                    medicamento</button>
                </div>
                <div class="col-md-12 text-right">
                  <hr>
                  <router-link to="/prescricoes" class="btn btn-back mr-1">
                    <i class="fa fa-arrow-left"></i> Voltar
                  </router-link>
                  <a class="btn btn-add" @click="save">
                    Salvar
                    <i class="fa fa-save"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      prescricoes: {
        paciente_id: "",
        tipo_receita_id: "",
        medicamento: "",
      },
      medicamentos: [],
      tipo_receitas: [],
      pacientes: [],
      i: 1,
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "prescricoes";

      if (self.prescricoes.id) {
        api += '/' + self.prescricoes.id;
        self.prescricoes._method = 'PUT';
      }

      axios
        .post(api, self.prescricoes)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/prescricoes");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getPrescricoes: function (id) {
      const self = this;
      const api = self.$store.state.api + "prescricoes/" + id;

      axios
        .get(api)
        .then((response) => {
          self.prescricoes = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTipoReceitas: function (id) {
      const self = this;
      const api = self.$store.state.api + "tipo_receitas/";

      axios
        .get(api)
        .then((response) => {
          self.tipo_receitas = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getMedicamentos: function (id) {
      const self = this;
      const api = self.$store.state.api + "medicamentos/";

      axios
        .get(api)
        .then((response) => {
          self.medicamentos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getPacientes: function (id) {
      const self = this;
      const api = self.$store.state.api + "pacientes/";

      axios
        .get(api)
        .then((response) => {
          self.pacientes = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    self.getTipoReceitas();
    self.getMedicamentos();
    self.getPacientes();

    let id = self.$route.params.id;
    if (id) {
      self.getPrescricoes(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
