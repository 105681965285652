<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <label><span>*</span> Paciente</label>
                  <v-select
                    
                    :options="pacientes"
                    :reduce="(pacientes) => pacientes.id"
                    :label="'nome'"
                    v-model="atestado.paciente_id"
                    class="vselect"
                  ></v-select>
                </div>
                <div class="col-md-6">
                  <label><span>*</span> Médico responsável</label>
                  <v-select
                   
                    :options="profissionais"
                    :reduce="(profissionais) => profissionais.id"
                    :label="'nome'"
                    v-model="atestado.profissional_id"
                    class="vselect"
                  ></v-select>
                  </div>
                
                <div class="col-md-6">
                  <label><span>*</span> CID</label>
                  <v-select
                    v-model="atestado.cid_id"
                    :close-on-select="false"
                    multiple
                    class="vselect"
                    :options="cids"
                    :reduce="(cids) => cids.id"
                    :label="'label_cid'"
                  />
                </div>
                <div class="col-md-12">
                  <label><span>*</span> Descrição do Afastamento</label>
                  <textarea
                    v-model="atestado.descricao"
                    cols="30"
                    rows="3"
                    class="form-control"
                  />
                </div>
                <div class="col-md-12 mt-3 text-right">
                  <router-link to="/atestados" class="btn btn-back mr-1">
                    <i class="fa fa-arrow-left" /> Voltar
                  </router-link>
                  <a class="btn btn-add" @click="save">
                    Salvar
                    <i class="fa fa-save" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      atestado: {
        paciente_id: "",
        profissional_id: "",
        cid_id: "",
        descricao: "",
      },
      cids: [],
      pacientes: [],
      profissionais:[]
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "atestados";

      if (self.atestado.id) {
        api += "/" + self.atestado.id;
        self.atestado._method = "PUT";
      }

      axios
        .post(api, self.atestado)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/atestados");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getAtestados: function (id) {
      const self = this;
      const api = self.$store.state.api + "atestados/" + id + "?with[]=cids";

      axios
        .get(api)
        .then((response) => {
          self.atestado = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getCids: function (id) {
      const self = this;
      const api = self.$store.state.api + "cids/?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.cids = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getPacientes: function (id) {
      const self = this;
      const api = self.$store.state.api + "pacientes/";

      axios
        .get(api)
        .then((response) => {
          self.pacientes = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getProfissionais: function (id) {
      const self = this;
      const api = self.$store.state.api + "profissionais/";

      axios
        .get(api)
        .then((response) => {
          self.profissionais = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;

    self.getCids();
    self.getPacientes();
    self.getProfissionais();

    let id = self.$route.params.id;
    if (id) {
      self.getAtestados(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
