<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'agendamentosCrud'"
          crudName="Sala de espera"
          newText="Agendar consulta"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="false"
          :enableEdit="true"
          :enableDelete="true"
          :enableView="false"
          :enableReset="false"
          :dataForm="dataForm"
          :customFilters="customFilters"
          @filtered_ds="filtered_ds"
        >
        </base-crud>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      customFilters: [
        {
          name: "data_agendada",
          type: "calendar",
          call: "filtered_ds",
        },
      ],
      data_agendada: "",
      table: "agendamentosTable",
      showMessageImport: false,
      usersCrud: {},
      pages: [],
      url: "",
      columns: [
        "data_agendada",
        "horario",
        "paciente_id",
        "profissional_id",
        "especialidade_id",
        "status",
        "actions",
      ],
      tableData: [
        "data_agendada",
        "horario",
        "paciente_id",
        "profissional_id",
        "especialidade_id",
      ],
      options: {
        filterByColumn: true,
        debounce: 1000,
        sortIcon: {
          base: "fas",
          up: "fa-chevron-up",
          down: "fa-chevron-down",
          is: "fa-sort",
        },
        sortable: ["data_agendada"],
        filterByColumn: true,
        debounce: 1000,
        filterable: [],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          data_agendada: "Data agendada",
          horario: "Horário",
          paciente_id: "Nome do paciente",
          profissional_id: "Profissional",
          especialidade_id: "Especialidade",
          status: "Status",
          actions: "Ações",
        },

        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {
          status: [
            {
              id: 0,
              text: "Aguardando",
            },
            {
              id: 1,
              text: "Agendado",
            },
            {
              id: 2,
              text: "Ausente",
            },
            {
              id: 3,
              text: "Cancelado",
            },
            {
              id: 3,
              text: "Confirmado",
            },
          ],
        },
        templates: {
          paciente_id: function (h, row, index) {
            return row.paciente ? row.paciente.nome : "Indisponível";
          },
          especialidade_id: function (h, row, index) {
            return row.especialidade ? row.especialidade.nome : "Indisponível";
          },
          profissional_id: function (h, row, index) {
            return row.profissional ? row.profissional.nome : "Indisponível";
          },

          status: function (h, row, index) {
            if (row.status == 0) {
              return (
                <h5>
                  <span class="badge badge-info">Aguardando</span>
                </h5>
              );
            }
            if (row.status == 1) {
              return (
                <h5>
                  <span class="badge badge-secondary">Agendado</span>
                </h5>
              );
            }
            if (row.status == 2) {
              return (
                <h5>
                  <span class="badge badge-warning">Ausente</span>
                </h5>
              );
            }
            if (row.status == 3) {
              return (
                <h5>
                  <span class="badge badge-danger">Cancelado</span>
                </h5>
              );
            }
            if (row.status == 4) {
              return (
                <h5>
                  <span class="badge badge-success">Confirmado</span>
                </h5>
              );
            }
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;

          if (
            this.$parent.$parent.$parent.data_agendada != null &&
            this.$parent.$parent.$parent.data_agendada != ""
          ) {
            requestData.params.data_agendada =
              this.$parent.$parent.$parent.data_agendada;
          }

          requestData.params.query = "";
          requestData.params.with = [
            "paciente",
            "especialidade",
            "empresa",
            "profissional",
          ];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      endPoint: "agendamentos/",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    filtered_ds(date) {
      this.data_agendada = date;
    },
    query: function (query) {
      let columns = {
        id: "id",
        paciente_id: "paciente[nome]",
        cpf: "cpf",
        data_agendada: "data_agendada",
        profissional_id: "profissional[nome]",
        especialidade_id: "especialidade[nome]",
        horario: "horario",
        company_id: "empresa[nome_fantasia]",
      };
      let filters = "";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>