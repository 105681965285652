<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Adicionar Plano</div>
            </div>
            <div class="card-body">
              <hr />
              <br />
              <div class="row">

                <div class="col-md-4">
                  <label><span class="required">*</span> Plano</label>
                  <input type="text" id="plano" class="form-control" v-model="plano.nome">

                </div>
                <div class="col-md-4">
                  <label><span class="required">*</span> Tipo de comercialização</label>
                  <input type="text" class="form-control" v-model="plano.tipo_comercializacao">

                </div>
                <div class="col-md-4">
                  <label><span class="required">*</span> Benefícios</label>
                  <input type="text" class="form-control" v-model="plano.beneficios">

                </div>
                <div class="col-md-6">
                  <label><span class="required">*</span> Convênio</label>
                  <v-select :options="convenios" :reduce="(convenios) => convenios.id" :label="'nome'"
                    v-model="plano.convenio_id" class="vselect"></v-select>

                </div>
                <div class="col-md-6">
                  <label><span class="required">*</span> Empresa</label>
                  <v-select :options="empresas" :reduce="(empresas) => empresas.id" :label="'nome_fantasia'"
                    v-model="plano.empresa_id" class="vselect"></v-select>

                </div>


                <div class="col-md-12 text-right">
                  <hr>
                  <router-link to="/planos" class="btn btn-back mr-1">
                    <i class="fa fa-arrow-left"></i> Voltar
                  </router-link>
                  <a class="btn btn-add" @click="save">
                    Salvar
                    <i class="fa fa-save"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      plano: {
        nome: "",
        tipo_comercializacao: "",
        beneficios: "",
        convenio_id: "",
        empresa_id: "",
      },
      convenios: [],
      empresas: [],

    };
  },
  computed: {

  },

  methods: {



    save: function () {
      const self = this;
      let api = self.$store.state.api + "planos";

      if (self.plano.id) {
        api += '/' + self.plano.id;
        self.plano._method = 'PUT';
      }

      axios
        .post(api, self.plano)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/planos");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },



    getPlanos: function (id) {
      const self = this;
      const api = self.$store.state.api + "planos/" + id;

      axios
        .get(api)
        .then((response) => {
          self.plano = response.data.data[0];

        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getConvenios: function () {
      const self = this;
      const api = self.$store.state.api + "convenios/";

      axios
        .get(api)
        .then((response) => {
          self.convenios = response.data.data;

        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEmpresas: function () {
      const self = this;
      const api = self.$store.state.api + "empresas/";

      axios
        .get(api)
        .then((response) => {
          self.empresas = response.data.data;

        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

  },
  mounted: function () {
    const self = this;
    self.getConvenios();
    self.getEmpresas();
    $("#plano").focus();

    let id = self.$route.params.id;
    if (id) {
      self.getPlanos(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>