<template>
  <div>
    <div class="main-content" v-if="carregado">
      <div class="row">
        <div class="col-md-8">
          <div class="card" :style="'border-left: 8px solid ' + paciente.cor + ';'">
            <div class="card-body">
              <div class="row">
                <div class="d-flex justify-content-between flex-column col-xl-8 col-12">
                  <div class="d-flex justify-content-start flex-wrap">
                    <label for="pacientePic" id="labelPacientePic">
                      <span class="b-avatar badge-light-danger rounded btn" style="width: 23vh; height: 23vh;"
                        title="Alterar imagem">
                        <span class="b-avatar-img">
                          <img class="btn-avatar" :src="paciente.foto_processada + '?cache=' + reloadImg" alt="avatar"
                            id="pacienteImg" />
                        </span>
                      </span>
                      <input type="file" name="pacientePic" id="pacientePic" class="d-none" v-on:input="pacienteImg">
                    </label>
                    <div class="d-flex flex-column ml-1 mt-2 mt-sm-0">
                      <div class="mb-1">
                        <h4 class="mb-0" style="font-size: 14px;">
                          {{ paciente.nome_completo }}
                        </h4>
                        <span class="card-text" style="font-size: 12px; margin-top: 4px;"> <b>Idade: </b>{{ diffIdade
                          }}</span>
                        <span><br>
                          <router-link :to="'/pacientes/edit/' + paciente.id" class="btn btn-back mt-1"
                            style="font-size: 12px; margin-top: 4px;">
                            <i class="fas fa-user-edit"></i> Editar informações
                          </router-link>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-12 pl-0 mt-3 mt-xl-0">
                  <table class="mt-2 mt-xl-0 w-100" style="font-size: 12px;">
                    <tr v-if="paciente.sexo">
                      <th class="pb-50">
                        <i class="fas fa-venus-mars mr-25"></i>
                        <span class="font-weight-bold">Sexo</span>
                      </th>
                      <td class="pb-50">
                        {{ paciente.sexo }}
                      </td>
                    </tr>
                    <tr v-if="paciente.cartao_sus">
                      <th class="pb-50">
                        <i class="fas fa-id-card mr-25"></i>
                        <span class="font-weight-bold">CNS</span>
                      </th>
                      <td class="pb-50 text-capitalize">
                        <p>{{ paciente.cartao_sus }}</p>
                      </td>
                    </tr>
                    <tr v-if="paciente.data_nascimento_formatado">
                      <th class="pb-50">
                        <i class="fas fa-calendar-alt mr-25"></i>
                        <span class="font-weight-bold">Nascimento</span>
                      </th>
                      <td class="pb-50 text-capitalize">
                        {{ paciente.data_nascimento_formatado }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <i class="fas fa-solid fa-phone fa-rotate-90 mr-25"></i>
                        <span class="font-weight-bold">Telefone</span>
                      </th>
                      <td class="pb-50 text-capitalize">
                        {{ paciente.telefone ? paciente.telefone : "Não possui" }}
                      </td>
                    </tr>
                    <tr v-if="paciente.celular">
                      <th class="pb-50">
                        <i class="fas fa-solid fa-mobile mr-25"></i>
                        <span class="font-weight-bold">Celular</span>
                      </th>
                      <td class="pb-50 text-capitalize">
                        {{ paciente.celular }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card text-center">
            <div class="d-flex align-items-center justify-content-center mt-2">
              <h3>Tempo de atendimento</h3>
            </div>
            <div class="d-flex align-items-center justify-content-center mt-2">
              <h4 id="timer">{{ formatarTempo(tempo_atendimento) }}</h4>
            </div>
            <div class="card-body">
              <div class="row d-flex justify-content-center align-items-center">
                <button class="btn btn-primary" @click="iniciarContagem" v-if="!timer && !pausado">
                  Iniciar
                  <i class="fas fa-solid fa-play"></i>
                </button>
                <button class="btn btn-primary" @click="reiniciarContagem" v-if="pausado">
                  Reiniciar
                  <i class="fas fa-sync-alt"></i>
                </button>
                <button class="btn btn-primary" @click="pausarContagem" v-if="timer">
                  Pausar
                  <i class="fas fa-solid fa-pause"></i>
                </button>
                <b-button class="btn btn-primary ml-1" v-b-modal.modalDecl modal-center @click="pararContagem" v-if="timer">
                  Encerrar
                  <i class="fas fa-solid fa-stop"></i>
                </b-button>
              </div>
              <div class="row d-flex justify-content-center align-items-center mt-3">
                <button type="button" class="btn btn-back" data-toggle="modal" @click="getAtendimentos"
                  data-target="#modalHistorico" style="font-size: 12px; margin-top: 4px;"><i
                    class="fas fa-download"></i>
                  Histórico do paciente
                </button>
                <span>
                  <a :href="paciente.url" target="_blank" class="btn btn-back ml-1"
                    style="font-size: 12px; margin-top: 4px;">
                    <i class="fas fa-download"></i> Baixar perfil
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div class="modal fade" id="modalHistorico" tabindex="-1" role="dialog" aria-labelledby="historicoModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    Histórico
                  </h5>
                  <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="page">
                    <div v-if="atendimentos.length > 0">
                      <div class="timeline">
                        <div class="timeline__group">
                          <div v-for="atendimento_obj in atendimentos" :key="atendimento_obj.id">
                            <span class="timeline__year time" aria-hidden="true">
                              {{ atendimento_obj.atendimento.data_inicio_f }}
                            </span>
                            <div class="timeline__cards">
                              <div class="timeline__card cartao">
                                <header class="card__header">
                                  <time class="time">
                                    <span class="time__day">Atendimento com: </span>
                                    <span class="time__month">{{ atendimento_obj.user.name }}</span>
                                  </time>
                                </header>
                                <div class="card__content">
                                  <p>{{ paciente.nome }}</p>
                                  <div v-for="sinal_vital in atendimento_obj.sinal_vital" :key="sinal_vital.id">
                                    <p>{{ sinal_vital.bpm }}</p>
                                  </div>
                                  <div v-for="exame in atendimento_obj.exame" :key="exame.id">
                                    <p>{{ exame.titulo }}</p>
                                  </div>
                                  <div v-for="laudo in atendimento_obj.laudo" :key="laudo.id">
                                    <p>{{ laudo.hipotese }}</p>
                                  </div>
                                  <div v-for="documento in atendimento_obj.documento" :key="documento.id">
                                    <p>{{ documento.titulo }}</p>
                                  </div>
                                  <div v-for="declaracao in atendimento_obj.declaracao" :key="declaracao.id">
                                    <p>{{ declaracao.descricao }}</p>
                                  </div>
                                  <div v-for="atestado in atendimento_obj.atestado" :key="atestado.id">
                                    <p>{{ atestado.titulo }}</p>
                                  </div>
                                  <div v-for="prescricao in atendimento_obj.prescricao" :key="prescricao.id">
                                    <p>{{ prescricao.titulo }}</p>
                                  </div>
                                  <div v-for="diagnostico in atendimento_obj.diagnostico" :key="diagnostico.id">
                                    <p>{{ diagnostico.titulo }}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row bg mt-3" style="text-align: center" v-else>
                      <div class="col-md-12">
                        <strong class="text-center">Nenhum atendimento feito</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row justify-content-center">
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a href="#posts" data-toggle="tab" class="nav-link active show"><i class="fas fa-comments"
                        style="font-size: 24px" data-toggle="tooltip" data-placement="top" title="Posts"></i></a>
                    <h6 class="nav-link">Posts</h6>
                  </li>
                  <li class="nav-item" @click="getSinais">
                    <a href="#sinais_vitais" data-toggle="tab" class="nav-link"><i class="fas fa-heartbeat"
                        style="font-size: 24px" data-toggle="tooltip" data-placement="top"
                        title="Sinais Vitais"></i></a>
                    <h6 class="nav-link">Sinais Vitais</h6>
                  </li>
                  <li class="nav-item" @click="getExames">
                    <a href="#exames" data-toggle="tab" class="nav-link"><i class="fas fa-file-medical"
                        style="font-size: 24px" data-toggle="tooltip" data-placement="top" title="Exames"></i></a>
                    <h6 class="nav-link">Exames</h6>
                  </li>
                  <li class="nav-item" @click="getLaudos">
                    <a href="#laudos" data-toggle="tab" class="nav-link"><i class="fas fa-file" style="font-size: 24px"
                        data-toggle="tooltip" data-placement="top" title="Laudos"></i></a>
                    <h6 class="nav-link">Laudos</h6>
                  </li>
                  <li class="nav-item" @click="getDocumentos">
                    <a href="#documentos" data-toggle="tab" class="nav-link"><i class="fas fa-file-pdf"
                        style="font-size: 24px" data-toggle="tooltip" data-placement="top" title="Documentos"></i></a>
                    <h6 class="nav-link">Documentos</h6>
                  </li>
                  <li class="nav-item">
                    <a href="#consultas" data-toggle="tab" class="nav-link"><i class="fas fa-list"
                        style="font-size: 24px" data-toggle="tooltip" data-placement="top" title="Consultas"></i></a>
                    <h6 class="nav-link">Consultas</h6>
                  </li>
                  <li class="nav-item" @click="getDeclaracoes">
                    <a href="#declaracoes" data-toggle="tab" class="nav-link"><i class="fas fa-chart-line"
                        style="font-size: 24px" data-toggle="tooltip" data-placement="top" title="Declarações"></i></a>
                    <h6 class="nav-link">Declarações</h6>
                  </li>
                  <li class="nav-item" @click="getAtestados">
                    <a href="#atestados" data-toggle="tab" class="nav-link"><i class="fas fa-edit"
                        style="font-size: 24px" data-toggle="tooltip" data-placement="top" title="Atestados"></i></a>
                    <h6 class="nav-link">Atestados</h6>
                  </li>
                  <li class="nav-item" @click="getPrescricoes">
                    <a href="#prescricoes" data-toggle="tab" class="nav-link"><i class="fas fa-prescription-bottle-alt"
                        style="font-size: 24px" data-toggle="tooltip" data-placement="top" title="Prescrições"></i></a>
                    <h6 class="nav-link">Prescrições</h6>
                  </li>
                  <li class="nav-item" @click="getAlergias">
                    <a href="#alergias" data-toggle="tab" class="nav-link"><i class="fas fa-solid fa-disease"
                        style="font-size: 24px" data-toggle="tooltip" data-placement="top" title="Alergias"></i></a>
                    <h6 class="nav-link">Alergias</h6>
                  </li>
                  <li class="nav-item" @click="getDiagnosticos">
                    <a href="#diagnosticos" data-toggle="tab" class="nav-link"><i
                        class="fas fa-solid fa-comment-medical" style="font-size: 24px" data-toggle="tooltip"
                        data-placement="top" title="Diagnósticos"></i></a>
                    <h6 class="nav-link">Diagnósticos</h6>
                  </li>
                </ul>
              </div>
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <div class="tab-content">
                    <div id="posts" class="tab-pane fade active show">
                      <!----------------------- POSTS ---------------------->
                      <div class="my-post-content pt-3" v-if="timer">
                        <div class="post-input">
                          <ckeditor v-model="post.descricao" :editor="editor" />
                        </div>
                        <button class="btn btn-primary mt-3" @click="savePost">
                          Salvar Registro
                        </button>
                      </div>
                      <div class="row justify-content-center">
                        <div class="col-md-10">
                          <div class="mt-3">
                            <div class="row bg mt-3" style="text-align: left" v-for="post_obj in posts"
                              :key="post_obj.id">
                              <div class="col-md-12">
                                <strong class="text-center">{{
      post_obj.data_criado
    }}</strong>
                                <br /><br />
                                <span class="text-start" style="
                                    font-size: 15px;
                                    font-style: italic;
                                  " v-html="post_obj.descricao"></span>
                                <span class="text-start" style="
                                    font-size: 15px;
                                    font-style: italic;
                                  "><b>Inserido por:</b> {{ post_obj.user.name }}</span>
                                <br /><br />
                              </div>
                            </div>
                            <div class="row bg mt-3" style="text-align: center" v-if="posts.length <= 0">
                              <div class="col-md-12">
                                <strong class="text-center">Nenhum post feito</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="sinais_vitais" class="tab-pane fade centered">
                      <!----------------------- SINAIS VITAIS ---------------------->
                      <div class="row justify-content-center">
                        <div class="col-md-10">
                          <div class="mt-3">
                            <button type="button" class="btn btn-add mt-1" style="padding: 8px" data-toggle="modal"
                              data-target="#modalSinais" v-if="timer">
                              Lançar sinais vitais
                            </button>
                            <div class="modal fade" id="modalSinais" tabindex="-1" role="dialog"
                              aria-labelledby="siModalLabel" aria-hidden="true">
                              <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5 class="modal-title">
                                      Lançar sinais vitais
                                    </h5>
                                    <button type="button" class="close" data-dismiss="modal">
                                      <span>&times;</span>
                                    </button>
                                  </div>
                                  <div class="modal-body row">
                                    <div class="col-md-3">
                                      <label>Peso (Kg)</label>
                                      <input type="number" class="form-control" v-model="sinais_form.peso" min="0" />
                                    </div>
                                    <div class="col-md-3">
                                      <label>Altura (Cm)</label>
                                      <input type="number" class="form-control" v-model="sinais_form.altura" min="0" />
                                    </div>
                                    <div class="col-md-3">
                                      <label>Temperatura (Cº)</label>
                                      <input type="number" class="form-control" v-model="sinais_form.temperatura"
                                        min="0" @input="limitarValor" />
                                    </div>
                                    <div class="col-md-3">
                                      <label>Frequência cardíaca(BPM)</label>
                                      <input type="number" class="form-control" v-model="sinais_form.bpm" min="0" />
                                    </div>
                                    <div class="col-md-3">
                                      <label>Resp. por minuto (MR)</label>
                                      <input type="number" class="form-control" v-model="sinais_form.mr" min="0" />
                                    </div>
                                    <div class="col-md-3">
                                      <label>Saturação (%)</label>
                                      <input type="number" class="form-control" v-model="sinais_form.saturacao" min="0"
                                        @input="limitarValor" />
                                    </div>
                                    <div class="col-md-3">
                                      <label>Glicemia</label>
                                      <input type="number" class="form-control" v-model="sinais_form.glicemia_capilar"
                                        min="0" @input="limitarValor" />
                                    </div>
                                    <div class="col-md-3">
                                      <label>PA Diastólica</label>
                                      <input type="text" class="form-control" v-model="sinais_form.pa_diastolica"
                                        @input="limitarValor" />
                                    </div>
                                    <div class="col-md-3">
                                      <label>PA Sistólica</label>
                                      <input type="text" class="form-control" v-model="sinais_form.pa_sistolica"
                                        @input="limitarValor" />
                                    </div>
                                    <div class="col-md-3">
                                      <label>Checklist</label>
                                      <v-select :options="checklists" :reduce="(checklists) => checklists.id"
                                        :label="'nome'" v-model="sinais_form.checklist_id" class="vselect"></v-select>
                                    </div>
                                    <div class="col-md-6 mt-1" v-if="sinais_form.checklist_id">
                                      <label>Itens do Checklist selecionado:</label>
                                      <div class="col-md-3" v-for="(tac, index) in itens_checklists" :key="tac.id"
                                        v-if="tac.checklist_id === sinais_form.checklist_id">
                                        <div class="col-md-3">
                                          <div class="form-check">
                                            <input v-model="itens_checklists[index].value" class="form-check-input"
                                              type="checkbox" value="true" :id="tac.id" />
                                            <label class="form-check-label" :for="tac.id" style="font-size: 16px">{{
      tac.nome }}</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                      Fechar
                                    </button>
                                    <button type="button" class="btn btn-primary" data-dismiss="modal"
                                      @click="saveSinais">
                                      Enviar
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row bg mt-3" style="text-align: left" v-for="sinal in sinais" :key="sinal.id">
                              <div class="col-md-12">
                                <strong class="text-center">Sinais medidos em
                                </strong>
                                <span class="text-start" style="
                                    font-size: 15px;
                                    font-style: italic;
                                    color: #a1a1a1;
                                  ">{{ sinal.data_gravado }}</span>
                                <br />
                                <strong class="text-center">Sinais medidos por
                                </strong>
                                <span class="text-start" style="
                                font-size: 15px;
                                font-style: italic;
                                color: #a1a1a1;
                                ">{{ sinal.user.name }}</span>
                                <br />
                              </div>
                              <div class="col-md-4 mt-1">
                                <strong class="text-center">Peso:</strong><br />
                                <span class="text-start" style="
                                    font-size: 15px;
                                    font-style: italic;
                                    color: #a1a1a1;
                                  ">{{ sinal.peso }} Kg</span>
                              </div>
                              <div class="col-md-4 mt-1">
                                <strong class="text-center">Altura:</strong><br />
                                <span class="text-start" style="
                                    font-size: 15px;
                                    font-style: italic;
                                    color: #a1a1a1;
                                  ">{{ sinal.altura }} cm</span>
                              </div>
                              <div class="col-md-4 mt-1">
                                <strong class="text-center">Temperatura:</strong><br />
                                <span class="text-start" style="
                                    font-size: 15px;
                                    font-style: italic;
                                    color: #a1a1a1;
                                  ">{{ sinal.temperatura }}ºC</span>
                              </div>
                              <div class="col-md-4 mt-1">
                                <strong class="text-center">Frequência cardíaca:</strong><br />
                                <span class="text-start" style="
                                    font-size: 15px;
                                    font-style: italic;
                                    color: #a1a1a1;
                                  ">{{ sinal.bpm }}</span>
                              </div>
                              <div class="col-md-4 mt-1">
                                <strong class="text-center">Respirações por minuto:</strong><br />
                                <span class="text-start" style="
                                    font-size: 15px;
                                    font-style: italic;
                                    color: #a1a1a1;
                                  ">{{ sinal.mr }}</span>
                              </div>
                              <div class="col-md-4 mt-1">
                                <strong class="text-center">Saturação:</strong><br />
                                <span class="text-start" style="
                                    font-size: 15px;
                                    font-style: italic;
                                    color: #a1a1a1;
                                  ">{{ sinal.saturacao }}%</span>
                              </div>
                              <div class="col-md-4 mt-1">
                                <strong class="text-center">Glicemia:</strong><br />
                                <span class="text-start" style="
                                    font-size: 15px;
                                    font-style: italic;
                                    color: #a1a1a1;
                                  ">{{ sinal.glicemia_capilar }}</span>
                              </div>
                              <div class="col-md-4 mt-1">
                                <strong class="text-center">Pressão diastólica:</strong><br />
                                <span class="text-start" style="
                                    font-size: 15px;
                                    font-style: italic;
                                    color: #a1a1a1;
                                  ">{{ sinal.pa_diastolica }}</span>
                              </div>
                              <div class="col-md-4 mt-1">
                                <strong class="text-center">Pressão sistólica:</strong><br />
                                <span class="text-start" style="
                                    font-size: 15px;
                                    font-style: italic;
                                    color: #a1a1a1;
                                  ">{{ sinal.pa_sistolica }}</span>
                              </div>
                              <div class="col-md-4 mt-1">
                                <strong class="text-center">Checklist:</strong><br />
                                <span class="text-start" style="
                                    font-size: 15px;
                                    font-style: italic;
                                    color: #a1a1a1;
                                  ">{{ sinal.checklist.nome }}</span>
                              </div>
                              <div class="col-md-4 mt-1">
                                <strong class="text-center">Itens do Checklist selecionado:</strong><br />
                                <span class="text-start" style="
                                    font-size: 15px;
                                    font-style: italic;
                                    color: #a1a1a1;
                                " v-for="item in sinal.itens_checklist" :key="item.itens_id">
                                  {{ item.item[0].nome }}<br>
                                </span>
                              </div>
                            </div>
                            <div class="row bg mt-3" style="text-align: center" v-if="sinais.length <= 0">
                              <div class="col-md-12">
                                <strong class="text-center">Nenhum sinal medido</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="exames" class="tab-pane fade centered">
                      <!----------------------- EXAMES ---------------------->
                      <div class="row justify-content-center">
                        <div class="col-md-12">
                          <button class="btn btn-add" data-toggle="modal" data-target="#modalNovoExame"
                            v-if="timer">Solicitar novo
                            exame</button>
                          <button class="btn btn-add ml-1" data-toggle="modal" data-target="#modalExameRealizado"
                            v-if="timer">Inserir exame já realizado</button>
                          <div class="modal fade" id="modalNovoExame" tabindex="-1" role="dialog"
                            aria-labelledby="exameModalLabel" aria-hidden="true">
                            <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title">Novo exame</h5>
                                  <button type="button" class="close" data-dismiss="modal">
                                    <span>&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <label>Procedimento a realizar</label>
                                      <v-select :options="procedimentos" :reduce="(procedimentos) => procedimentos.id"
                                        :label="'nome'" v-model="exame.procedimento_id" class="vselect" />
                                    </div>
                                    <div class="col-md-12">
                                      <label>Exame a realizar</label>
                                      <input type="text" class="form-control" v-model="exame.titulo" />
                                    </div>
                                    <div class="col-md-12">
                                      <label>Descrição</label>
                                      <ckeditor :editor="editor" v-model="exame.descricao" />
                                    </div>
                                  </div>
                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                    Fechar
                                  </button>
                                  <button type="button" class="btn btn-primary" data-dismiss="modal" @click="saveExame">
                                    Enviar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="modal fade" id="modalExameRealizado" tabindex="-1" role="dialog"
                            aria-labelledby="exameModalLabel" aria-hidden="true">
                            <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title">Exame realizado</h5>
                                  <button type="button" class="close" data-dismiss="modal">
                                    <span>&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <div class="row">
                                    <div class="col-md-12">
                                      <label>Procedimento realizado</label>
                                      <v-select :options="procedimentos" :reduce="(procedimentos) => procedimentos.id"
                                        :label="'nome'" v-model="exame.procedimento_id" class="vselect" />
                                    </div>
                                    <div class="col-md-12">
                                      <label>Exame realizado</label>
                                      <input type="text" class="form-control" v-model="exame.titulo" />
                                    </div>
                                    <div class="col-md-12">
                                      <label>Descrição</label>
                                      <ckeditor :editor="editor" v-model="exame.descricao" />
                                    </div>
                                    <div class="col-md-12">
                                      <label>Data de realização</label>
                                      <input type="date" class="form-control" v-model="exame.data_realizado" />
                                    </div>
                                    <div class="form-group col-md-12 mt-1">
                                      <label for="examePic">Arquivo</label>
                                      <br />
                                      <input type="file" id="exameRealizadoPic" name="exameRealizadoPic" />
                                    </div>
                                  </div>
                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                    Fechar
                                  </button>
                                  <button type="button" class="btn btn-primary" data-dismiss="modal" @click="saveExameRealizado">
                                    Enviar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <div class="row mt-3" style="text-align: center">
                              <div class="col-md-12">
                                <b>Exames a realizar</b>
                                <hr>
                                <table class="table mt-3">
                                  <thead>
                                    <tr>
                                      <th scope="col">ID</th>
                                      <th scope="col">Exame</th>
                                      <th scope="col">Descrição</th>
                                      <th scope="col">Solicitado por</th>
                                      <th scope="col">Data Solicitado</th>
                                      <th scope="col">Upload</th>
                                    </tr>
                                  </thead>
                                  <tbody v-for="exame_obj in examesSemAnexo" :key="exame_obj.id">
                                    <td>{{ exame_obj.id }}</td>
                                    <td>{{ exame_obj.titulo }}</td>
                                    <td v-html="exame_obj.descricao"></td>
                                    <td> {{ exame_obj.user.name }}</td>
                                    <td> {{ exame_obj.data_solicitado_formatado }}</td>
                                    <td><button class="btn btn-add" style="padding: 10px" data-toggle="modal"
                                        :data-target="'#modalExame' + exame_obj.id" v-if="timer">
                                        Upload de arquivo <i class="fas fa-upload"></i>
                                      </button></td>
                                    <div class="modal fade" :id="'modalExame' + exame_obj.id" tabindex="-1"
                                      role="dialog" aria-labelledby="exameModalLabel" aria-hidden="true">
                                      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                          <div class="modal-header">
                                            <h5 class="modal-title">Upload de exame: {{ exame_obj.id }}</h5>
                                            <button type="button" class="close" data-dismiss="modal">
                                              <span>&times;</span>
                                            </button>
                                          </div>
                                          <div class="modal-body">
                                            <div class="row">
                                              <div class="col-md-12">
                                                <label>Data de realização</label>
                                                <input type="date" class="form-control"
                                                  v-model="exame.data_realizado" />
                                              </div>
                                              <div class="form-group col-md-12 mt-1">
                                                <label for="examePic">Arquivo</label>
                                                <br />
                                                <input type="file" id="examePic" name="examePic" />
                                              </div>
                                            </div>
                                          </div>
                                          <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                              Fechar
                                            </button>
                                            <button type="button" class="btn btn-primary" data-dismiss="modal"
                                              @click="saveArquivoExame(exame_obj.id)">
                                              Enviar
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </tbody>
                                  <tr v-if="examesSemAnexo.length === 0">
                                    <td colspan="6" class="text-center"><b>Nenhum exame para ser feito</b></td>
                                  </tr>
                                </table>
                              </div>
                              <div class="col-md-12 mt-3">
                                <b>Exames realizados</b>
                                <hr>
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">ID</th>
                                      <th scope="col">Exame</th>
                                      <th scope="col">Descrição</th>
                                      <th scope="col">Solicitado por</th>
                                      <th scope="col">Data Realizado</th>
                                      <th scope="col">Baixar</th>
                                    </tr>
                                  </thead>
                                  <tbody v-for="exame_obj in examesComAnexo" :key="exame_obj.id">
                                    <td>{{ exame_obj.id }}</td>
                                    <td>{{ exame_obj.titulo }}</td>
                                    <td v-html="exame_obj.descricao"></td>
                                    <td> {{ exame_obj.user.name }}</td>
                                    <td> {{ exame_obj.data_realizado_formatado }}</td>
                                    <td><a :href="exame_obj.url" target="_blank" class="btn btn-add mt-1">Baixar<i
                                          class="fas fa-download"></i></a></td>
                                  </tbody>
                                  <tr v-if="examesComAnexo.length === 0">
                                    <td colspan="6" class="text-center"><b>Nenhum exame foi realizado</b></td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="laudos" class="tab-pane fade centered">
                      <!----------------------- LAUDOS ---------------------->
                      <div class="row justify-content-center">
                        <div class="col-md-12">
                          <div class="mt-3">
                            <button class="btn btn-add" style="padding: 10px" data-toggle="modal"
                              data-target="#modalLaudo" v-if="timer">
                              Novo laudo <i class="fas fa-upload"></i>
                            </button>
                            <div class="modal fade" id="modalLaudo" tabindex="-1" role="dialog"
                              aria-labelledby="exameModalLabel" aria-hidden="true">
                              <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5 class="modal-title">
                                      Criação de laudo
                                    </h5>
                                    <button type="button" class="close" data-dismiss="modal">
                                      <span>&times;</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <label>Hipótese diagnóstica</label>
                                        <textarea cols="30" rows="3" type="text" class="form-control"
                                          v-model="laudo_form.hipotese" />
                                      </div>
                                      <div class="col-md-12">
                                        <label>Observação</label>
                                        <textarea cols="30" rows="3" type="text" class="form-control"
                                          v-model="laudo_form.observacao" />
                                      </div>
                                      <div class="col-md-6">
                                        <label>Data da solicitação</label>
                                        <input type="date" class="form-control" v-model="laudo_form.data_solicitado" />
                                      </div>
                                      <div class="col-md-6">
                                        <label>Data de realização</label>
                                        <input type="date" class="form-control" v-model="laudo_form.data_realizado" />
                                      </div>
                                      <div class="col-md-12">
                                        <label>CID</label>
                                        <v-select v-model="laudo_form.cid_id" multiple :close-on-select="false"
                                          class="vselect" :options="cids" :reduce="(cids) => cids.id"
                                          :label="'label_cid'" />
                                        <div class="form-group col-md-4 mt-1">
                                          <label for="laudoPic">Arquivo</label>
                                          <br />
                                          <input type="file" id="laudoPic" name="laudoPic" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                      Fechar
                                    </button>
                                    <button type="button" class="btn btn-primary" data-dismiss="modal"
                                      @click="saveLaudo">
                                      Enviar
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-md-12">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Hipotese Diagnostica</th>
                                    <th scope="col">Observação</th>
                                    <th scope="col">Solicitado em</th>
                                    <th scope="col">Data Realizado</th>
                                    <th scope="col">CIDS</th>
                                    <th scope="col">Realizado por</th>
                                    <th scope="col">Laudo PDF</th>
                                    <th scope="col">Baixar</th>
                                  </tr>
                                </thead>
                                <tbody v-for="laudo in laudos" :key="laudo.id">
                                  <td>{{ laudo.id }}</td>
                                  <td>{{ laudo.hipotese }}</td>
                                  <td>{{ laudo.observacao }}</td>
                                  <td>{{ laudo.data_solicitado_formatado }}</td>
                                  <td>{{ laudo.data_realizado_formatado }}</td>
                                  <td><span v-for="cid in laudo.cids" :key="cid.id">
                                      {{ cid.cid.label_cid }}
                                    </span></td>
                                  <td> {{ laudo.user.name }}</td>
                                  <td><a :href="laudo.url_pdf" target="_blank" class="btn btn-add mt-1 mr-1">Laudo PDF
                                      <i class="fas fa-download"></i></a></td>
                                  <td><a :href="laudo.url" target="_blank" class="btn btn-add mt-1">Baixar <i
                                        class="fas fa-download"></i></a></td>
                                </tbody>
                                <tr v-if="laudos.length === 0">
                                  <td colspan="9" class="text-center"><b>Nenhum laudo encontrado</b></td>
                                </tr>
                              </table>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div id="documentos" class="tab-pane fade centered">
                      <!----------------------- DOCUMENTOS ---------------------->
                      <div class="row justify-content-center">
                        <div class="col-md-12">
                          <div class="mt-3">
                            <button class="btn btn-add" style="padding: 10px" data-toggle="modal"
                              data-target="#modalDoc" v-if="timer">
                              Novo documento <i class="fas fa-upload"></i>
                            </button>
                            <div class="modal fade" id="modalDoc" tabindex="-1" role="dialog"
                              aria-labelledby="exameModalLabel" aria-hidden="true">
                              <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5 class="modal-title">
                                      Adicionar documento
                                    </h5>
                                    <button type="button" class="close" data-dismiss="modal">
                                      <span>&times;</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <div class="col-md-12">
                                      <label>Título</label>
                                      <input type="text" class="form-control" v-model="doc_form.titulo" />
                                    </div>
                                    <div class="col-md-12">
                                      <label>Descrição</label>
                                      <textarea cols="30" rows="3" type="text" class="form-control"
                                        v-model="doc_form.descricao" />
                                    </div>
                                    <div class="form-group col-md-4 mt-1">
                                      <label for="docPic">Arquivo</label>
                                      <br />
                                      <input type="file" id="docPic" name="docPic" />
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                      Fechar
                                    </button>
                                    <button type="button" class="btn btn-primary" data-dismiss="modal" @click="saveDoc">
                                      Enviar
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-md-12">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Titulo</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col">Upload feito por</th>
                                    <th scope="col">Baixar</th>
                                  </tr>
                                </thead>
                                <tbody v-for="documento in documentos" :key="documento.id">
                                  <td>{{ documento.id }}</td>
                                  <td>{{ documento.titulo }}</td>
                                  <td v-html="documento.descricao"></td>
                                  <td>{{ documento.user.name }}</td>
                                  <td><a :href="documento.url" target="_blank" class="btn btn-add mt-1">Baixar<i
                                        class="fas fa-download"></i></a></td>
                                </tbody>
                                <tr v-if="documentos.length === 0">
                                  <td colspan="5" class="text-center"><b>Nenhum documento encontrado</b></td>
                                </tr>
                              </table>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div id="consultas" class="tab-pane fade centered">
                      <!----------------------- CONSULTAS ---------------------->
                      <div class="row justify-content-center">
                        <div class="col-md-12">
                          <div class="mt-3">
                            <button class="btn btn-add" style="padding: 10px" data-toggle="collapse"
                              data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"
                              @click="getEventos">
                              Agendar consulta <i class="fas fa-calendar"></i>
                            </button>
                            <div class="collapse" id="collapseExample">
                              <div class="row mt-3">
                                <div class="col-md-12">
                                  <FullCalendar :options="calendarOptions" />
                                </div>
                              </div>
                              <div class="modal fade" id="modalEvento" tabindex="-1" role="dialog"
                                aria-labelledby="modalEvento" aria-hidden="true">
                                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h5 class="modal-title">Evento</h5>
                                      <button type="button" class="close" data-dismiss="modal">
                                        <span>&times;</span>
                                      </button>
                                    </div>
                                    <div class="modal-body row">
                                      <div class="col-md-6">
                                        <label>Data</label>
                                        <input type="date" class="form-control" v-model="agenda.data_agendada" />
                                      </div>
                                      <div class="col-md-6">
                                        <label>Hora</label>
                                        <input type="time" class="form-control" v-model="agenda.horario" />
                                      </div>
                                      <div class="col-md-12">
                                        <label>Paciente</label>
                                        <v-select disabled v-model="paciente.nome" class="vselect" />
                                      </div>
                                      <div class="col-md-12">
                                        <label>Especialidade</label>
                                        <v-select :options="especialidades" :reduce="(especialidades) =>
      especialidades.id
      " :label="'nome'" v-model="agenda.especialidade_id" @input="getEspecialidadeProfissional" class="vselect" />
                                      </div>
                                      <div class="col-md-12">
                                        <label>Profissional</label>
                                        <v-select :options="profissionais" :reduce="(profissionais) => profissionais.id
      " :label="'nome'" v-model="agenda.profissional_id" class="vselect" />
                                      </div>
                                      <!-- <div class="col-md-12">
                                        <label>Empresa</label>
                                        <v-select :options="empresas" :reduce="(empresas) => empresas.id"
                                          :label="'nome_fantasia'" v-model="empresa_id" class="vselect" />
                                      </div> -->
                                      <!-- <div class="col-md-12">
                                        <label>CPF</label>
                                        <input type="text" class="form-control" v-model="agenda.cpf" />
                                      </div> -->
                                      <!-- <div class="col-md-12">
                                        <label>Nº do convênio</label>
                                        <input type="text" class="form-control" v-model="agenda.cartao_convenio" />
                                      </div>
                                      <div class="col-md-12">
                                        <label>Cartão SUS</label>
                                        <input type="text" class="form-control" v-model="agenda.cartao_sus" />
                                      </div> -->
                                    </div>
                                    <div class="modal-footer">
                                      <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                        Fechar
                                      </button>
                                      <button type="button" class="btn btn-primary" data-dismiss="modal"
                                        @click="gravaEvento">
                                        Enviar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <br />
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div class="col-md-10">
                          <div class="row bg mt-3" v-for="agenda_obj in agendas" :key="agenda_obj.id">
                            <div class="col-md-12">
                              <strong class="text-center">Consulta realizada em: </strong>
                              <span class="text-start" style="
                                  font-size: 15px;
                                  font-style: italic;
                                  color: #a1a1a1;
                                ">{{ agenda_obj.data_agendada_f }} às
                                {{ agenda_obj.horario }}</span>
                              <br /><br />
                              <strong class="text-center">Profissional:
                              </strong>
                              <span class="text-start" style="
                                  font-size: 15px;
                                  font-style: italic;
                                  color: #a1a1a1;
                                ">{{ agenda_obj.profissional.nome }} ({{
      agenda_obj.profissional.conselho
    }})</span>
                              <br /><br />
                              <strong class="text-center">Empresa: </strong>
                              <!-- <span class="text-start" style="
                                  font-size: 15px;
                                  font-style: italic;
                                  color: #a1a1a1;
                                ">{{ agenda_obj.empresa.nome_fantasia
                                }}</span> -->
                              <br /><br />
                              <strong class="text-center">Especialidade:</strong>
                              <span class="text-start" style="
                                  font-size: 15px;
                                  font-style: italic;
                                  color: #a1a1a1;
                                ">{{ agenda_obj.especialidade.nome }}</span>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div id="declaracoes" class="tab-pane fade centered">
                      <!----------------------- DECLARAÇÕES ---------------------->
                      <div class="row justify-content-center">
                        <div class="col-md-12">
                          <div class="mt-3">
                            <b-modal id="modalDecl" size="lg" hide-footer title="Adicionar declaração" centered>
                              <div class="row">
                                <div class="col-md-12">
                                  <label>Fins</label>
                                  <input type="text" class="form-control" v-model="declaracao_form.fins" />
                                </div>
                              </div> 
                              <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                  Fechar
                                </button>
                                <button type="button" class="btn btn-primary" data-dismiss="modal"
                                  @click="saveDeclaracao">
                                  Enviar
                                </button>
                              </div>
                            </b-modal>
                          </div>
                          <div class="row mt-3">
                            <div class="col-md-12">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Profissional</th>
                                    <th scope="col">Emissão</th>
                                    <th scope="col">Descrição</th>
                                  </tr>
                                </thead>
                                <tbody v-for="declaracao in declaracoes" :key="declaracao.id">
                                  <td>{{ declaracao.id }}</td>
                                  <td>{{ declaracao.user.name }}</td>
                                  <td>{{ declaracao.data_emissao_parse }}</td>
                                  <td v-html="declaracao.descricao"></td>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="row bg mt-3" style="text-align: center" v-if="declaracoes.length <= 0">
                            <div class="col-md-12">
                              <strong class="text-center">Nenhuma declaração encontrada</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="atestados" class="tab-pane fade centered">
                      <div class="row justify-content-center">
                        <div class="col-md-12">
                          <div class="mt-3">
                            <button class="btn btn-add" style="padding: 10px" data-toggle="modal"
                              data-target="#modalAtestado" v-if="timer">
                              Novo Atestado <i class="fas fa-upload"></i>
                            </button>
                            <div class="modal fade" id="modalAtestado" tabindex="-1" role="dialog"
                              aria-labelledby="atestadoModalLabel" aria-hidden="true">
                              <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5 class="modal-title">Novo atestado</h5>
                                    <button type="button" class="close" data-dismiss="modal">
                                      <span>&times;</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <div class="col-md-12">
                                      <label><span class="required">*</span>
                                        Título</label>
                                      <input type="text" class="form-control" v-model="atestado.titulo" />
                                    </div>
                                    <div class="col-md-12">
                                      <label>CID</label>
                                      <v-select v-model="atestado.cid_id" :close-on-select="false" multiple
                                        class="vselect" :options="cids" :reduce="(cids) => cids.id"
                                        :label="'label_cid'" />
                                    </div>
                                    <div class="col-md-12">
                                      <label>Quantidade de dias afastados</label>
                                      <input type="text" v-model="atestado.dias_afastamento" class="form-control" />
                                    </div>
                                    <div class="col-md-12">
                                      <label>Qual dia passará a ser valido</label>
                                      <input type="datetime-local" v-model="atestado.dia_valido" class="form-control" />
                                    </div>
                                    <div class="col-md-12">
                                      <label>Descrição do Afastamento</label>
                                      <textarea v-model="atestado.descricao" cols="30" rows="3" class="form-control" />
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                      Fechar
                                    </button>
                                    <button type="button" class="btn btn-primary" data-dismiss="modal"
                                      @click="saveAtestado">
                                      Salvar
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3" style="text-align: center">
                            <div class="col-md-12">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Atestado</th>
                                    <th scope="col">Descricao</th>
                                    <th scope="col">Emitido em</th>
                                    <th scope="col">Emitido por</th>
                                    <th scope="col">Baixar</th>
                                  </tr>
                                </thead>
                                <tbody v-for="atestado_obj in atestados" :key="atestado_obj.id">
                                  <td>{{ atestado_obj.id }}</td>
                                  <td>{{ atestado_obj.titulo }}</td>
                                  <td>{{ atestado_obj.descricao }}</td>
                                  <td>{{ atestado_obj.created_at }}</td>
                                  <td>{{ atestado_obj.profissional.nome }}</td>
                                  <td><a :href="atestado_obj.url" target="_blank" class="btn btn-add mt-1">Baixar<i
                                        class="fas fa-download"></i></a></td>
                                </tbody>
                                <tr v-if="atestados.length === 0">
                                  <td colspan="6" class="text-center"><b>Nenhum atestado encontrado</b></td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="prescricoes" class="tab-pane fade centered">
                      <div class="row justify-content-center">
                        <div class="col-md-12">
                          <div class="mt-3">
                            <button class="btn btn-add" style="padding: 10px" data-toggle="modal"
                              data-target="#modalPrescrição" v-if="timer">
                              Nova Prescrição <i class="fas fa-upload"></i>
                            </button>
                            <div class="modal fade" id="modalPrescrição" tabindex="-1" role="dialog"
                              aria-labelledby="prescriçãoModalLabel" aria-hidden="true">
                              <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5 class="modal-title">Medicamento em uso</h5>
                                    <button type="button" class="close" data-dismiss="modal">
                                      <span>&times;</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <div class="col-md-12 mt-1">
                                      <label>Medicamento:</label>
                                      <v-select :options="medicamentos" :reduce="medicamento => medicamento.id"
                                        :label="'nome'" v-model="prescricao.medicamento_id" class="vselect"></v-select>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                      Fechar
                                    </button>
                                    <button type="button" class="btn btn-primary" data-dismiss="modal"
                                      @click="savePrescricao">
                                      Salvar
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <table class="table mt-3">
                            <thead>
                              <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Medicamento em uso</th>
                                <th scope="col">Receitado em</th>
                                <th scope="col">Receitado por</th>
                                <th scope="col">Descrição</th>
                              </tr>
                            </thead>
                            <tbody v-for=" prescricao_obj  in  prescricoes " :key="prescricao_obj.id">
                              <tr>

                              </tr>
                            </tbody>
                            <tr v-if="prescricoes.length === 0">
                              <td colspan="6" class="text-center"><b>Nenhuma prescrição encontrada</b></td>
                            </tr>
                          </table>

                        </div>
                      </div>
                    </div>
                    <div id="alergias" class="tab-pane fade centered">
                      <div class="row justify-content-center">
                        <div class="col-md-12">
                          <div class="mt-3">
                            <button class="btn btn-add" style="padding: 10px" data-toggle="modal"
                              data-target="#modalAlergia" v-if="timer">
                              Adicionar alergias <i class="fas fa-upload"></i>
                            </button>
                            <div class="modal fade" id="modalAlergia" tabindex="-1" role="dialog"
                              aria-labelledby="alergiaModalLabel" aria-hidden="true">
                              <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5 class="modal-title">Adicionar alergias</h5>
                                    <button type="button" class="close" data-dismiss="modal">
                                      <span>&times;</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <div class="col-md-12">
                                      <label><span class="required">*</span>
                                        Alergias</label>
                                      <input type="text" class="form-control" v-model="alergia.titulo" />
                                    </div>
                                    <div class="col-md-12">
                                      <label><span class="required">*</span>Descrição</label>
                                      <textarea v-model="alergia.descricao" cols="30" rows="3" class="form-control" />
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                      Fechar
                                    </button>
                                    <button type="button" class="btn btn-primary" data-dismiss="modal"
                                      @click="saveAlergia">
                                      Salvar
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3" style="text-align: center">
                            <div class="col-md-12">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Alergia</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col">Inserido em</th>
                                    <th scope="col">Inserido por</th>
                                  </tr>
                                </thead>
                                <tbody v-for="alergia_obj in alergias" :key="alergia_obj.id">
                                  <td>{{ alergia_obj.id }}</td>
                                  <td>{{ alergia_obj.titulo }}</td>
                                  <td>{{ alergia_obj.descricao }}</td>
                                  <td>{{ alergia_obj.created_at }}</td>
                                  <td>{{ alergia_obj.user.name }}</td>
                                </tbody>
                                <tr v-if="alergias.length === 0">
                                  <td colspan="6" class="text-center"><b>Nenhuma alergia encontrada</b></td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="diagnosticos" class="tab-pane fade centered">
                      <div class="row justify-content-center">
                        <div class="col-md-12">
                          <div class="mt-3">
                            <button class="btn btn-add" style="padding: 10px" data-toggle="modal"
                              data-target="#modalDiagnostico" v-if="timer">
                              Adicionar diagnóstico <i class="fas fa-upload"></i>
                            </button>
                            <div class="modal fade" id="modalDiagnostico" tabindex="-1" role="dialog"
                              aria-labelledby="diagnosticoModalLabel" aria-hidden="true">
                              <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5 class="modal-title">Adicionar diagnóstico</h5>
                                    <button type="button" class="close" data-dismiss="modal">
                                      <span>&times;</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <div class="col-md-12">
                                      <label><span class="required">*</span>
                                        Diagnóstico</label>
                                      <input type="text" class="form-control" v-model="diagnostico.titulo" />
                                    </div>
                                    <div class="col-md-12">
                                      <label>CID</label>
                                      <v-select v-model="diagnostico.cid_id" multiple :close-on-select="false"
                                        class="vselect" :options="cids" :reduce="(cids) => cids.id"
                                        :label="'label_cid'" />
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                      Fechar
                                    </button>
                                    <button type="button" class="btn btn-primary" data-dismiss="modal"
                                      @click="saveDiagnostico">
                                      Salvar
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3" style="text-align: center">
                            <div class="col-md-12">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Diagnóstico</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col">Inserido em</th>
                                    <th scope="col">Inserido por</th>
                                  </tr>
                                </thead>
                                <tbody v-for="diagnostico_obj in diagnosticos" :key="diagnostico_obj.id">
                                  <td>{{ diagnostico_obj.id }}</td>
                                  <td>{{ diagnostico_obj.titulo }}</td>
                                  <td>{{diagnostico_obj.descricao}}</td>
                                  <td>{{diagnostico_obj.created_at}}</td>
                                  <td>{{diagnostico_obj.user.name}}</td>
                                </tbody>
                                <tr v-if="diagnosticos.length === 0">
                                  <td colspan="5" class="text-center"><b>Nenhum diagnóstico encontrado</b></td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ptLocale from "@fullcalendar/core/locales/pt-br";
import { responseInterceptor } from "http-proxy-middleware";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "imageUpload",
  data() {
    return {
      carregado: false,
      reloadImg: 0,
      tempo_atendimento: 0,
      timer: null,
      pausado: false,
      checklists: [],
      itens_checklists: [],
      paciente: {
        cep: "",
        logradouro: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        created_at: "",
        cor: "",
        foto_processada: "",
      },
      pacientes: [],
      user: [],
      alergia: {},
      alergias: [],
      prescricao: {
        medicamento_id: '',
      },
      prescricoes: [],
      atendimento: {},
      atendimentos: [],
      atendimento_atual: {},
      atestado: {},
      atestados: [],
      cid: {
        label_cid: "",
      },
      cids: [],
      diagnostico: {},
      diagnosticos: [],
      exame: {
        data_realizado: "",
      },
      exames: [],
      consultasList: true,
      agendas: [],
      atestado: {
        titulo: '',
        descricao: '',
        dias_afastamento: '',
      },
      atestados: [],
      cids: [],
      declaracoes: [],
      declaracao_form: {},
      documentos: [],
      doc_form: {},
      laudo_form: {},
      laudos: [],
      post: {
        paciente_id: "",
      },
      posts: [],
      sinais: {},
      sinais_form: [],
      medicamentos: [],
      empresas: [],
      convenios: [],
      convenio: {},
      editor: ClassicEditor,

      agenda: [],
      profissional_id: "",
      especialidade_id: "",
      empresa_id: "",
      agendamento: {},
      pacientes: [],
      profissionais: [],
      especialidades: [],
      empresas: [],


      mode: "",
      fullscreen: false,
      loaded: false,
      changes: false,
      fullPage: true,
      isLoading: true,
      resource_selected: "",
      originalEvents: [],
      infoModal: "",
      tes: {},
      new_demand: "",
      date_formated: "",
      calendarOptions: {
        locale: ptLocale,
        eventTimeFormat: {
          hour: "numeric",
          minute: "2-digit",
          meridiem: false,
        },

        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "timeGridWeek",
        },
        initialView: "timeGridWeek",
        height: "500px",
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: false,
        eventClick: this.eventoClicado,
        select: this.selecionaData,
        eventDrop: this.arrastaEvento,
        events: [],
        slotMinTime: "09:00:00",
        slotMaxTime: "18:01:00",
      },

      valorMaximo: 300,
      valorMaximoTempetura: 60,
      valorMaximoSaturacao: 100,
      valorMaximoGlicemia: 1000,

      filters: {
        show_finished: 0,
      },
      customers: [],
      order_types: [],
      users: [],
      projects: [],
      calendarApi: "",
      prioridades: [],
    };
  },
  computed: {
    diffIdade() {
      const self = this;

      var a = moment();
      var b = moment(self.paciente.data_nascimento);

      var years = a.diff(b, "year");
      b.add(years, "years");

      var months = a.diff(b, "months");
      b.add(months, "months");

      var days = a.diff(b, "days");

      if (years < 100) {
        return years + " anos, " + months + " meses, " + days + " dias";
      }

      if ((years) => 100) {
        return years + " anos, " + months + " meses, " + days + " dias";
      }
    },

    examesSemAnexo() {
      return this.exames.filter(exame => !exame.anexo);
    },
    examesComAnexo() {
      return this.exames.filter(exame => exame.anexo);
    }
  },
  methods: {
    pacienteImg: function () { // SALVA IMAGEM DO PACIENTE
      const self = this;
      let api = self.$store.state.api + "pacientes/image/update/" + self.paciente.id;
      let fd = self.mFDImg();
      $('#pacienteImg').hide();

      axios
        .post(api, fd)
        .then(() => {
          self.getPacientes(self.paciente.id);
          self.reloadImg += 1;

          setTimeout(() => {
            $('#pacienteImg').show();
          }, 800);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          $('#pacienteImg').show();
        });
    },
    mFDImg: function () {
      const self = this;
      let fd = new FormData();
      let anexoImg = document.getElementById("pacientePic");

      console.log(anexoImg.files[0]);
      fd.append("paciente_id", self.paciente.id);
      fd.append("anexo", anexoImg.files[0] ? anexoImg.files[0] : "");

      anexoImg.value = '';

      return fd;
    },
    limitarValor() {
      if (this.sinais_form.pa_sistolica > this.valorMaximo) {
        this.sinais_form.pa_sistolica = this.valorMaximo;
      }
      if (this.sinais_form.pa_diastolica > this.valorMaximo) {
        this.sinais_form.pa_diastolica = this.valorMaximo;
      }
      if (this.sinais_form.temperatura > this.valorMaximoTempetura) {
        this.sinais_form.temperatura = this.valorMaximoTempetura;
      }
      if (this.sinais_form.saturacao > this.valorMaximoSaturacao) {
        this.sinais_form.saturacao = this.valorMaximoSaturacao;
      }
      if (this.sinais_form.glicemia_capilar > this.valorMaximoGlicemia) {
        this.sinais_form.glicemia_capilar = this.valorMaximoGlicemia;
      }
    },
    iniciarContagem() {
      const self = this;
      self.timer = setInterval(() => {
        self.tempo_atendimento++;
      }, 1000);

      self.atendimento.data_inicio = moment().format("YYYY-MM-DD HH:mm:ss");

      let api = self.$store.state.api + "atendimentos";


      axios
        .post(api, {
          ...self.atendimento,
          paciente_id: self.paciente.id
        })
        .then((response) => {
          self.atendimento_atual = response.data;
          self.$message(
            "Sucesso",
            `Atendimento iniciado`,
            "success"
          );
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    reiniciarContagem() {
      const self = this;
      self.timer = setInterval(() => {
        self.tempo_atendimento++;
      }, 1000);
      this.pausado = false;
    },
    pausarContagem() {
      clearInterval(this.timer);
      this.timer = null;
      this.pausado = true;
    },
    pararContagem() {
      const self = this;
      let api = self.$store.state.api + "atendimentos/finalizar";

      axios
        .post(api, { id: self.atendimento_atual.id })
        .then((response) => {

          self.$message(
            "Sucesso",
            `Atendimento encerrado`,
            "success"
          );

          clearInterval(this.timer);
          self.timer = null;
          self.tempo_atendimento = 0;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    formatarTempo(tempo_atendimento) {
      const horas = Math.floor(tempo_atendimento / 3600);
      const minutos = Math.floor((tempo_atendimento % 3600) / 60);
      const segundos = tempo_atendimento % 60;

      return `${this.adicionarZeroEsquerda(horas)}:${this.adicionarZeroEsquerda(
        minutos
      )}:${this.adicionarZeroEsquerda(segundos)}`;
    },

    adicionarZeroEsquerda(valor) {
      return valor < 10 ? `0${valor}` : valor;
    },

    savePrescricao: function () {
      // SALVA APLICAÇÃO
      const self = this;
      let api = self.$store.state.api + "prescricoes";

      axios
        .post(api, {
          ...self.prescricao,
          paciente_id: self.paciente_id,
          atendimento_id: self.atendimento_atual.id
        })
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );

          self.getPrescricoes(self.paciente.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    saveAtestado: function () {
      // SALVA ATESTADOS
      const self = this;
      let api = self.$store.state.api + "atestados";

      axios
        .post(api, {
          ...self.atestado,
          paciente_id: self.paciente.id,
          atendimento_id: self.atendimento_atual.id
        })
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );

          self.atestado = "";

          self.getAtestados();

        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    mFDAtestado: function () {
      const self = this;
      let fd = new FormData();

      fd.append("titulo", self.atestado.titulo);
      fd.append("cid_id", self.atestado.cid_id);
      fd.append("descricao", self.atestado.descricao);
      fd.append("paciente_id", self.paciente.id);
      fd.append("dias_afastamento", self.atestado.dias_afastamento);
      fd.append("atendimento_id", self.atendimento_atual.id);

      self.atestado.titulo = "";
      self.atestado.cid_id = "";
      self.atestado.descricao = "";
      self.atestado.dias_afastamento = "";

      return fd;
    },
    saveDeclaracao: function () {
      // SALVA DECLARAÇÕES
      const self = this;
      let api = self.$store.state.api + "declaracoes";

      axios
        .post(api, {
          ...self.declaracao_form,
          paciente_id: self.paciente.id,
          atendimento_id: self.atendimento_atual.id
        })
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.declaracao = "";
          self.getDeclaracoes(self.paciente.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    saveDoc: function () {
      // SALVA DOCUMENTOS
      const self = this;
      let api = self.$store.state.api + "documentos";
      let fd = self.mFDDoc();

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.getDocumentos(self.paciente.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    mFDDoc: function () {
      const self = this;
      let fd = new FormData();
      let anexoDoc = document.getElementById("docPic");

      fd.append("titulo", self.doc_form.titulo);
      fd.append("descricao", self.doc_form.descricao);
      fd.append("paciente_id", self.paciente.id);
      fd.append("atendimento_id", self.atendimento_atual.id);

      fd.append("anexo", anexoDoc.files[0] ? anexoDoc.files[0] : "");

      self.doc_form.titulo = "";
      self.doc_form.descricao = "";
      anexoDoc.value = "";

      return fd;
    },
    saveExame: function () {
      // SALVA EXAMES
      const self = this;
      let api = self.$store.state.api + "exames";

      axios
        .post(api, {
          ...self.exame,
          paciente_id: self.paciente.id,
          atendimento_id: self.atendimento_atual.id
        })
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.exame = {};
          self.getExames(self.paciente.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    saveArquivoExame: function (id) {
      // SALVA EXAMES
      const self = this;
      let api = self.$store.state.api + "exames/salvar_arquivo/" + id;
      let fd = self.mFDExame();
      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.exame = {};
          self.getExames(self.paciente.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },

    saveExameRealizado: function () {
      // SALVA EXAMES
      const self = this;
      let api = self.$store.state.api + "exames/exames_realizados";
      let fd = self.mFDExameRealizado();
      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.exame = {};
          self.getExames(self.paciente.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },

    mFDExame: function () {
      const self = this;
      let fd = new FormData();
      let anexoExame = document.getElementById("examePic");

      fd.append("data_realizado", self.exame.data_realizado);
      fd.append("paciente_id", self.paciente.id);
      fd.append("atendimento_id", self.atendimento_atual.id);

      fd.append("anexo", anexoExame.files[0] ? anexoExame.files[0] : "");

      return fd;
    },

    mFDExameRealizado: function () {
      const self = this;
      let fd = new FormData();
      let anexoExame = document.getElementById("exameRealizadoPic");

      fd.append("procedimento_id", self.exame.procedimento_id);
      fd.append("titulo", self.exame.titulo);
      fd.append("descricao", self.exame.descricao);
      fd.append("data_realizado", self.exame.data_realizado);
      fd.append("paciente_id", self.paciente.id);
      fd.append("atendimento_id", self.atendimento_atual.id);

      fd.append("anexo", anexoExame.files[0] ? anexoExame.files[0] : "");

      return fd;
    },

    saveLaudo: function () {
      // SALVA LAUDOS
      const self = this;
      let api = self.$store.state.api + "laudos";
      let fd = self.mFDLaudo();

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );

          self.getLaudos(self.paciente.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    mFDLaudo: function () {
      const self = this;
      let fd = new FormData();
      let anexoLaudo = document.getElementById("laudoPic");

      fd.append("data_realizado", self.laudo_form.data_realizado);
      fd.append("data_solicitado", self.laudo_form.data_solicitado);
      fd.append("hipotese", self.laudo_form.hipotese);
      fd.append("observacao", self.laudo_form.observacao);
      fd.append("paciente_id", self.paciente.id);
      fd.append("cid_id", self.laudo_form.cid_id);
      fd.append("atendimento_id", self.atendimento_atual.id);

      fd.append("anexo", anexoLaudo.files[0] ? anexoLaudo.files[0] : "");

      self.laudo_form.data_realizado = "";
      self.laudo_form.data_solicitado = "";
      self.laudo_form.hipotese = "";
      self.laudo_form.observacao = "";
      self.laudo_form.cid_id = "";
      anexoLaudo.value = "";

      return fd;
    },

    saveDiagnostico: function () {
      // SALVA LAUDOS
      const self = this;
      let api = self.$store.state.api + "diagnosticos";

      axios
        .post(api, {
          ...self.diagnostico,
          paciente_id: self.paciente.id,
          atendimento_id: self.atendimento_atual.id
        })
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.diagnostico = "",
            self.getDiagnosticos(self.paciente.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    savePaciente: function () {
      // SALVA EDIÇÃO DO PACIENTE
      const self = this;
      let api = self.$store.state.api + "pacientes";

      if (self.paciente.id) {
        api += "/" + self.paciente.id;
        self.paciente._method = "PUT";
      }

      axios
        .post(api, self.paciente)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );

          self.getPacientes(self.paciente.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    savePost: function () {
      // SALVA POSTS
      const self = this;
      let api = self.$store.state.api + "posts";

      axios
        .post(api, {
          ...self.post,
          paciente_id: self.paciente.id,
          atendimento_id: self.atendimento_atual.id
        })
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.getPosts(self.paciente.id);
          self.post.descricao = "";
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    saveSinais: function () {
      // SALVA SINAIS VITAIS|ANAMNESE
      const self = this;
      let api = self.$store.state.api + "sinais-vitais";
      let fd = self.mFDSinais();

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.getSinais(self.paciente.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    mFDSinais: function () {
      const self = this;
      let fd = new FormData();

      fd.append("peso", self.sinais_form.peso);
      fd.append("altura", self.sinais_form.altura);
      fd.append("temperatura", self.sinais_form.temperatura);
      fd.append("bpm", self.sinais_form.bpm);
      fd.append("mr", self.sinais_form.mr);
      fd.append("saturacao", self.sinais_form.saturacao);
      fd.append("glicemia_capilar", self.sinais_form.glicemia_capilar);
      fd.append("pa_sistolica", self.sinais_form.pa_sistolica);
      fd.append("pa_diastolica", self.sinais_form.pa_diastolica);
      fd.append("paciente_id", self.paciente.id);
      fd.append("atendimento_id", self.atendimento_atual.id);
      fd.append("checklist_id", self.sinais_form.checklist_id);
      self.itens_checklists.forEach((element) => {
        console.log(element);
        if (element.value == true) {
          fd.append("checklist_itens[]", element.id);
        }
      });

      self.sinais_form.peso = "";
      self.sinais_form.altura = "";
      self.sinais_form.temperatura = "";
      self.sinais_form.bpm = "";
      self.sinais_form.mr = "";
      self.sinais_form.saturacao = "";
      self.sinais_form.glicemia_capilar = "";
      self.sinais_form.pa_sistolica = "";
      self.sinais_form.pa_diastolica = "";
      self.sinais_form.checklist_id = "";

      return fd;
    },

    saveAlergia: function () {
      // SALVA APLICAÇÃO
      const self = this;
      let api = self.$store.state.api + "alergias";


      axios
        .post(api, {
          ...self.alergia,
          paciente_id: self.paciente.id,
          atendimento_id: self.atendimento_atual.id
        })
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          self.alergia = "";
          self.getAlergias(self.paciente.id);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    // -------------------------------
    // FUNÇÕES DO CALENDÁRIO
    // -------------------------------
    arrastaEvento: function (selectEvent) {
      // ALTERA EVENTO AO ARRASTA-LO PELO CALENDÁRIO
      const self = this;
      let fd = new FormData();
      let api = self.$store.state.api + "agendamentos/" + selectEvent.event.id;

      fd.append("data_agendada", selectEvent.event.startStr.split("T")[0]);
      fd.append(
        "horario",
        selectEvent.event.startStr.split("T")[1].split("-")[0]
      );
      fd.append("_method", "PUT");

      axios
        .post(api, fd)
        .then((response) => {
          self.$message("Sucesso", `Evento alterado com sucesso`, "success");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    clearEvento: function () {
      // LIMPA FORMULÁRIO AO GRAVAR
      const self = this;

      self.agenda.id = "";
      self.agenda.data_agendada = "";
      self.agenda.horario = "";
      self.agenda.cpf = "";
      self.agenda.cartao_convenio = "";
      self.agenda.cartao_sus = "";

      self.agenda.push();

      self.paciente_id = "";
      self.profissional_id = "";
      self.especialidade_id = "";
      self.empresa_id = "";
    },
    eventoClicado: function (clickInfo) {
      // CHAMA MODAL COM FORMULÁRIO PREENCHIDO PARA EDIÇÃO DE EVENTO
      const self = this;
      const api = self.$store.state.api + "agendamentos/" + clickInfo.event.id;

      self.clearEvento();

      axios
        .get(api)
        .then((response) => {
          let dados = response.data.data[0];

          self.agenda.id = dados.id;
          self.agenda.data_agendada = dados.data_agendada;
          self.agenda.horario = dados.horario;
          self.agenda.cpf = dados.cpf;
          self.agenda.cartao_convenio = dados.cartao_convenio;
          self.agenda.cartao_sus = dados.cartao_sus;
          self.agenda.push();

          self.paciente_id = dados.paciente_id;
          self.profissional_id = dados.profissional_id;
          self.especialidade_id = dados.especialidade_id;
          self.empresa_id = dados.company_id;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });

      $("#modalEvento").modal("toggle");
    },
    gravaEvento: function () {
      // FUNÇÃO QUE GRAVA
      const self = this;
      let api = self.$store.state.api + "agendamentos";
      self.loaded = false;

      if (self.agenda.id) {
        api += "/" + self.agenda.id;
      }

      axios
        .post(api, {
          ...self.agenda,
          paciente_id: self.paciente.id,
        })
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );

          self.getEventos();

          setTimeout(() => {
            self.loaded = true;
          }, 1000);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    selecionaData: function (selectInfo) {
      // NOVO AGENDAMENTO AO SELECIONAR NO CALENDÁRIO
      const self = this;
      self.clearEvento();

      self.agenda.data_agendada = selectInfo.startStr.split("T")[0];
      self.agenda.horario = selectInfo.startStr.split("T")[1].split("-")[0];

      $("#modalEvento").modal("toggle");
    },
    // -------------------------------
    // FUNÇÕES DE GET SEM ID ATRIBUIDO
    // -------------------------------
    getCids: function () {
      // CIDS
      const self = this;
      const api = self.$store.state.api + "cids?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.cids = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    // getEmpresas: function () {
    //   // EMPRESAS
    //   const self = this;
    //   const api =
    //     self.$store.state.api +
    //     "empresas?paginated=false&orderByDesc=nome_fantasia";

    //   axios
    //     .get(api)
    //     .then((response) => {
    //       self.empresas = response.data.data;
    //     })
    //     .catch((error) => {
    //       self.$message(null, error.response.data, "error");
    //     });
    // },
    getEspecialidades: function () {
      // ESPECIALIDADES
      const self = this;
      const api =
        self.$store.state.api +
        "especialidades?paginated=false&orderByDesc=nome";

      axios
        .get(api)
        .then((response) => {
          self.especialidades = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getEspecialidadeProfissional: function (id) {
      const self = this;
      const api = self.$store.state.api + "profissionais?especialidade_id=" + id;

      axios
        .get(api)
        .then((response) => {
          self.profissionais = response.data.data;
          self.profissional_id = "";
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getMedicamentos: function () {
      // MEDICAMENTOS
      const self = this;
      const api =
        self.$store.state.api + "medicamentos?paginated=false&orderByDesc=nome";

      axios
        .get(api)
        .then((response) => {
          self.medicamentos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getProcedimentos: function () {
      // PROCEDIMENTOS
      const self = this;
      const api =
        self.$store.state.api +
        "procedimentos?paginated=false&orderByDesc=nome";

      axios
        .get(api)
        .then((response) => {
          self.procedimentos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    // getProfissionais: function () {
    //   // PROFISSIONAIS
    //   const self = this;
    //   const api =
    //     self.$store.state.api +
    //     "profissionais?paginated=false&orderByDesc=nome";

    //   axios
    //     .get(api)
    //     .then((response) => {
    //       self.profissionais = response.data.data;
    //     })
    //     .catch((error) => {
    //       self.$message(null, error.response.data, "error");
    //     });
    // },
    // -------------------------------
    // FUNÇÕES DE GET COM ID ATRIBUIDO
    // -------------------------------
    getAgendamentos: function () {
      // AGENDAMENTOS
      const self = this;
      const api =
        self.$store.state.api +
        "agendamentos?orderByDesc=id&paciente_id=" +
        self.paciente.id +
        "&with[]=profissional&with[]=empresa&with[]=especialidade&paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.agendas = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getPrescricoes: function () {
      const self = this;
      const api =
        self.$store.state.api +
        "prescricoes?orderByDesc=id&paciente_id=" +
        self.paciente.id +
        "&with[]=user&with[]=atendimento&paginated=false";

      axios
        .get(api)
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            self.prescricao = response.data.data[0];
            self.prescricoes = response.data.data;

          } else {
            self.prescricao = { medicamento_id: '' }; // Reinicialize o objeto prescricao
            self.prescricoes = [];
          }
          self.getMedicamentos();
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getChecklists: function () {
      const self = this;
      const api = self.$store.state.api + "checklists/";

      axios
        .get(api)
        .then((response) => {
          self.checklists = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getAlergias: function (id) {
      // APLICAÇÕES
      const self = this;
      const api =
        self.$store.state.api +
        "alergias?orderByDesc=id&paciente_id=" +
        self.paciente.id +
        "&with[]=user";

      axios
        .get(api)
        .then((response) => {
          self.alergias = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getAtendimentos: function (id) {
      // ATENDIMENTOS
      const self = this;
      const api = self.$store.state.api + "get-atendimentos";

      self.$loading(true);
      axios
        .post(api, { paciente_id: self.paciente.id })
        .then((response) => {
          self.atendimentos = response.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getAtestados: function (id) {
      const self = this;
      const api =
        self.$store.state.api +
        "atestados?orderByDesc=id&paciente_id=" +
        self.paciente.id +
        "&with[]=cids&with[]=profissional";

      axios
        .get(api)
        .then((response) => {
          self.atestados = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.carregamento.atestados = false;
        });
    },
    getDeclaracoes: function (id) {
      // DECLARAÇÕES
      const self = this;
      const api =
        self.$store.state.api +
        "declaracoes?orderByDesc=id&paciente_id=" +
        self.paciente.id +
        "&with[]=user";

      axios
        .get(api)
        .then((response) => {
          self.declaracoes = response.data.data;
        })

        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getDocumentos: function (id) {
      //DOCUMENTOS
      const self = this;
      const api =
        self.$store.state.api +
        "documentos?orderByDesc=id&paciente_id=" +
        self.paciente.id +
        "&with[]=user";

      axios
        .get(api)
        .then((response) => {
          self.documentos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEventos: function () {
      // EVENTOS
      const self = this;
      const api =
        self.$store.state.api + "agendamentos?orderByDesc=id&paciente_id=" + self.paciente.id;

      axios
        .get(api)
        .then((response) => {
          self.calendarOptions.events = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });

    },
    getExames: function (id) {
      // EXAMES
      const self = this;
      const api =
        self.$store.state.api +
        "exames?orderByDesc=id&paciente_id=" +
        self.paciente.id +
        "&with[]=user";

      axios
        .get(api)
        .then((response) => {
          self.exames = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getConvenios: function (id) {
      // EXAMES
      const self = this;
      const api =
        self.$store.state.api + "convenios?orderByDesc=id&paciente_id=" + id;

      axios
        .get(api)
        .then((response) => {
          self.convenios = response.data.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },
    getLaudos: function (id) {
      // LAUDOS
      const self = this;
      const api =
        self.$store.state.api +
        "laudos?orderByDesc=id&paciente_id=" +
        self.paciente.id +
        "&with[]=exame&with[]=cids&with[]=user";

      axios
        .get(api)
        .then((response) => {
          self.laudos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getPacientes: function (id) {
      // PACIENTES
      const self = this;
      const api = self.$store.state.api + "pacientes/" + id;

      axios
        .get(api)
        .then((response) => {
          self.paciente = response.data.data[0];
          self.paciente_id = response.data.data[0];
          self.pacientes = response.data.data;

          if (self.paciente.ultimo_atendimento != null) {
            if (self.paciente.ultimo_atendimento.data_fim == null) {
              self.atendimento_atual = self.paciente.ultimo_atendimento;

              self.timer = setInterval(() => {
                self.tempo_atendimento++;
              }, 1000);
            }
          }

          self.carregado = true;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getPosts: function (id) {
      // POSTS
      const self = this;
      const api =
        self.$store.state.api +
        "posts?orderByDesc=id&paciente_id=" +
        id +
        "&with[]=user";

      axios
        .get(api)
        .then((response) => {
          self.posts = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getDiagnosticos: function (id) {
      // POSTS
      const self = this;
      const api =
        self.$store.state.api +
        "diagnosticos?orderByDesc=id&paciente_id=" +
        self.paciente.id +
        "&with[]=user";

      axios
        .get(api)
        .then((response) => {
          self.diagnosticos = response.data.data;
        })

        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getSinais: function (id) {
      // SINAIS
      const self = this;
      const api =
        self.$store.state.api +
        "sinais-vitais?orderByDesc=id&paciente_id=" +
        self.paciente.id +
        "&with[]=user";

      axios
        .get(api)
        .then((response) => {
          self.sinais = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getPrioridades: function () {
      const self = this;
      const api = self.$store.state.api + "prioridades/";

      axios
        .get(api)
        .then((response) => {
          self.prioridades = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    // -------------------------------
    // FUNÇÕES AUXILIARES
    // -------------------------------
    buscar: function () {
      // BUSCA CEP
      var self = this;
      self.naoLocalizado = false;

      if (/^[0-9]{5}-[0-9]{3}$/.test(this.paciente.cep)) {
        $.getJSON(
          "https://viacep.com.br/ws/" + this.paciente.cep + "/json/",
          function (endereco) {
            if (endereco.erro) {
              self.endereco = {};
              $("#inputNumero").focus();
              self.naoLocalizado = true;
              return;
            }
            self.endereco = endereco;
            self.paciente.logradouro = self.endereco.logradouro;
            self.paciente.bairro = self.endereco.bairro;
            self.paciente.estado = self.endereco.uf;
            self.paciente.cidade = self.endereco.localidade;
            $("#inputNumero").focus();
          }
        );
      }
    },
  },
  mounted: function () {
    const self = this;
    self.$loading(true);
    self.getCids();
    //self.iniciarContagem(),
    self.getEspecialidades();
    // self.getMedicamentos();
    // self.getProfissionais();
    self.getProcedimentos();
    self.getPrioridades();
    self.user = self.$store.state.user;
    self.getChecklists();


    let id = self.$route.params.id;

    if (id) {
      self.getPacientes(id);
      // self.getAgendamentos(id);
      // self.getPrescricoes(id);
      // self.getAlergias(id);
      // self.getAtestados(id);
      // self.getDiagnosticos(id);
      // self.getDeclaracoes(id);
      // self.getDocumentos(id);
      // self.getEventos(id);
      // self.getExames(id);
      // self.getLaudos(id);
      self.getPosts(id);
      // self.getSinais(id);
      // self.getConvenios(id);
    }
  },
  components: {
    BaseCrud,
    FullCalendar,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.bg {
  background-color: #ebebeb;
  padding: 25px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}

.btn-avatar:hover {
  opacity: 0.4;
}

#labelPacientePic {
  background-image: url("../../../public/camera.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 10px;
}

.text-span {
  font-size: 15px;
  font-style: italic;
  color: #a1a1a1;
}

.padding-10px {
  padding: 10px;
}

[dir="ltr"] .nav-tabs .nav-link:after {
  left: 0;
  background: linear-gradient(30deg,
      #15488a,
      rgba(21, 72, 138, 0.5)) !important;
}

/*
!!!!
This pen is being refactored
!!!!
*/

/*
=====
DEPENDENCES
=====
*/

.r-title {
  margin-top: var(--rTitleMarginTop, 0) !important;
  margin-bottom: var(--rTitleMarginBottom, 0) !important;
}

p:not([class]) {
  line-height: var(--cssTypographyLineHeight, 1.78);
  margin-top: var(--cssTypographyBasicMargin, 1em);
  margin-bottom: 0;
}

p:not([class]):first-child {
  margin-top: 0;
}

/*
text component
*/

.text {
  display: var(--textDisplay, inline-flex);
  font-size: var(--textFontSize, 1rem);
}

/*
time component
*/

/*
core styles
*/

.time {
  display: var(--timeDisplay, inline-flex);
}

/*
extensions
*/

.time__month {
  margin-left: var(--timelineMounthMarginLeft, 0.25em);
}

/*
skin
*/

.time {
  padding: var(--timePadding, 0.25rem 1.25rem 0.25rem);
  background-color: var(--timeBackgroundColor, #f0f0f0);

  font-size: var(--timeFontSize, 0.75rem);
  font-weight: var(--timeFontWeight, 700);
  text-transform: var(--timeTextTransform, uppercase);
  color: var(--timeColor, currentColor);
}

/*
card component
*/

/*
core styles
*/

.card__content {
  margin-top: var(--cardContentMarginTop, 0.5rem);
}

/*
skin
*/

.cartao {
  border-radius: var(--timelineCardBorderRadius, 2px);
  border-left: var(--timelineCardBorderLeftWidth, 3px) solid var(--timelineCardBorderLeftColor, var(--uiTimelineMainColor));
  box-shadow: var(--timelineCardBoxShadow,
      0 1px 3px 0 rgba(0, 0, 0, 0.12),
      0 1px 2px 0 rgba(0, 0, 0, 0.24));
  background-color: var(--timelineCardBackgroundColor, #ebebeb);
}

/*
extensions
*/

.card__title {
  --rTitleMarginTop: var(--cardTitleMarginTop, 1rem);
  font-size: var(--cardTitleFontSize, 1.25rem);
}

/*
=====
CORE STYLES
=====
*/

.timeline {
  display: var(--timelineDisplay, grid);
  grid-row-gap: var(--timelineGroupsGap, 2rem);
}

/*
1. If timeline__year isn't displaed the gap between it and timeline__cards isn't displayed too
*/

.timeline__year {
  margin-bottom: 1.25rem;
  /* 1 */
}

.timeline__cards {
  display: var(--timeloneCardsDisplay, grid);
  grid-row-gap: var(--timeloneCardsGap, 1.5rem);
}

/*
=====
SKIN
=====
*/

.timeline {
  --uiTimelineMainColor: var(--timelineMainColor, #222);
  --uiTimelineSecondaryColor: var(--timelineSecondaryColor, #fff);

  border-left: var(--timelineLineWidth, 3px) solid var(--timelineLineBackgroundColor, var(--uiTimelineMainColor));
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

.timeline__year {
  --timePadding: var(--timelineYearPadding, 0.5rem 1.5rem);
  --timeColor: var(--uiTimelineSecondaryColor);
  --timeBackgroundColor: var(--uiTimelineMainColor);
  --timeFontWeight: var(--timelineYearFontWeight, 400);
}

.timeline__card {
  position: relative;
  margin-left: var(--timelineCardLineGap, 1rem);
}

/*
1. Stoping cut box shadow
*/

.timeline__cards {
  overflow: hidden;
  padding-top: 0.25rem;
  /* 1 */
  padding-bottom: 0.25rem;
  /* 1 */
}

.timeline__card::before {
  content: "";
  width: 100%;
  height: var(--timelineCardLineWidth, 2px);
  background-color: var(--timelineCardLineBackgroundColor,
      var(--uiTimelineMainColor));

  position: absolute;
  top: var(--timelineCardLineTop, 1rem);
  left: -50%;
  z-index: -1;
}

/*
=====
SETTINGS
=====
*/
/**/
.timeline {
  --timelineMainColor: #4557bb;
}

/*
=====
DEMO
=====
*/

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Open Sans,
    Ubuntu, Fira Sans, Helvetica Neue, sans-serif;
  color: #222;

  background-color: #f0f0f0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 1.5;
}

p:last-child {
  margin-bottom: 0;
}

.page {
  max-width: 47rem;
  padding: 5rem 2rem 3rem;
  margin-left: auto;
  margin-right: auto;
}

.substack {
  border: 1px solid #eee;
  background-color: #fff;
  width: 100%;
  max-width: 480px;
  height: 280px;
  margin: 1rem auto;
}

.linktr {
  display: flex;
  justify-content: flex-end;
  padding: 2rem;
  text-align: center;
}

.linktr__goal {
  background-color: rgb(209, 246, 255);
  color: rgb(8, 49, 112);
  box-shadow: rgb(8 49 112 / 24%) 0px 2px 8px 0px;
  border-radius: 2rem;
  padding: 0.75rem 1.5rem;
}

.r-link {
  --uirLinkDisplay: var(--rLinkDisplay, inline-flex);
  --uirLinkTextColor: var(--rLinkTextColor);
  --uirLinkTextDecoration: var(--rLinkTextDecoration, none);

  display: var(--uirLinkDisplay) !important;
  color: var(--uirLinkTextColor) !important;
  text-decoration: var(--uirLinkTextDecoration) !important;
}
</style>