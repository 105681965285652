import Crud from '../views/Convenio/Crud'
import Entity from '../views/Convenio/Add'

let routes = {
    path: '/convenios',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/convenios/add',
            name: 'NewConvenio',
            component: Entity,
            meta: {
                pageTitle: 'Convênios',
                breadcrumb: [
                    {
                        text: 'Convênios',
                        to: '/convenios',
                        active: false,
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/convenios/edit/:id?',
            name: 'EditConvenio',
            component: Entity,
            meta: {
                pageTitle: 'Convênios',
                breadcrumb: [
                    {
                        text: 'Convênios',
                        to: '/convenios',
                        active: false,
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/convenios',
            name: 'list-convenios',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Convênios',
                breadcrumb: [
                    {
                        text: 'Convênios',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
