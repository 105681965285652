<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <label><span>*</span> Nome Fantasia</label>
                  <input
                    v-model="empresa.nome_fantasia"
                    id="nomeFantasia"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-4">
                  <label><span>*</span> Razão Social</label>
                  <input
                    v-model="empresa.razao_social"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-4">
                  <label><span>*</span> CNPJ</label>
                  <input
                    v-model="empresa.cnpj"
                    v-mask="'99.999.999/9999-99'"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-1">
                  <label><span>*</span> CEP</label>
                  <input
                    type="text"
                    v-model="empresa.cep"
                    class="form-control"
                    v-on:keyup="buscar"
                    v-mask="'99999-999'"
                  />
                </div>
                <div class="col-md-5">
                  <label><span>*</span> Logradouro</label>
                  <input
                    v-model="empresa.logradouro"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-2">
                  <label><span>*</span> Número</label>
                  <input
                  id="inputNumero"
                    v-model="empresa.numero"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-4">
                  <label>Complemento</label>
                  <input
                    v-model="empresa.complemento"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-2">
                  <label><span>*</span> Bairro</label>
                  <input
                    v-model="empresa.bairro"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-3">
                  <label><span>*</span> Cidade</label>
                  <input
                    v-model="empresa.cidade"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-2">
                  <label><span>*</span> Estado</label>
                  <input
                    v-model="empresa.estado"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-2">
                  <label><span>*</span> Telefone Corporativo</label>
                  <input
                    v-model="empresa.telefone"
                    v-mask="'(99).99999-9999'"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-3">
                  <label><span>*</span>Email Comporativo</label>
                  <input
                    v-model="empresa.email"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-3">
                  <label><span>*</span>Logo da empresa</label>
                  <input
                    class="form-control"
                    type="file"
                    id="logoEmpresa"
                  />
                </div>
                <div class="col-md-12 text-right">
                  <hr />
                  <router-link to="/empresas" class="btn btn-back mr-1">
                    <i class="fa fa-arrow-left"></i> Voltar
                  </router-link>
                  <a class="btn btn-add" @click="save">
                    Salvar
                    <i class="fa fa-save"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      empresa: {
        nome_fantasia: "",
        razao_social: "",
        cnpj: "",
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        estado: "",
        telefone: "",
        email: "",
      },
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "empresas";
      let fd = self.mFDLogo();

      if (self.empresa.id) {
        api += "/" + self.empresa.id;
        self.empresa._method = "PUT";
      }

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/empresas");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    mFDLogo: function () {
      const self = this;
      let fd = new FormData();
      let logoEmpresa = document.getElementById("logoEmpresa");

      fd.append("nome_fantasia", self.empresa.nome_fantasia);
      fd.append("razao_social", self.empresa.razao_social);
      fd.append("cnpj", self.empresa.cnpj);
      fd.append("cep", self.empresa.cep);
      fd.append("logradouro", self.empresa.logradouro);
      fd.append("numero", self.empresa.numero);
      fd.append("complemento", self.empresa.complemento);
      fd.append("bairro", self.empresa.bairro);
      fd.append("cidade", self.empresa.cidade);
      fd.append("estado", self.empresa.estado);
      fd.append("telefone", self.empresa.telefone);
      fd.append("email", self.empresa.email);

      fd.append("logo", logoEmpresa.files[0] ? logoEmpresa.files[0] : "");

      return fd;
    },

    buscar: function () {
      var self = this;

      self.naoLocalizado = false;

      if (/^[0-9]{5}-[0-9]{3}$/.test(this.empresa.cep)) {
        $.getJSON(
          "https://viacep.com.br/ws/" + this.empresa.cep + "/json/",
          function (endereco) {
            if (endereco.erro) {
              self.endereco = {};
              $("#inputNumero").focus();
              self.naoLocalizado = true;
              return;
            }
            self.endereco = endereco;
            self.empresa.logradouro = self.endereco.logradouro;
            self.empresa.complemento = self.endereco.complemento;
            self.empresa.bairro = self.endereco.bairro;
            self.empresa.cidade = self.endereco.localidade;
            self.empresa.estado = self.endereco.uf;
            $("#inputNumero").focus();
          }
        );
      }
    },

    getEmpresas: function (id) {
      const self = this;
      const api = self.$store.state.api + "empresas/" + id;

      axios
        .get(api)
        .then((response) => {
          self.empresa = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    $("#nomeFantasia").focus();

    let id = self.$route.params.id;
    if (id) {
      self.getEmpresas(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
