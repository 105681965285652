<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <label><span class="required">*</span> Nome completo</label>
                  <input type="text" id="nomeCompleto" class="form-control" v-model="paciente.nome_completo" />
                </div>
                <div class="col-md-4">
                  <label><span class="required">*</span> E-mail</label>
                  <input type="text" class="form-control" v-model="paciente.email" />
                </div>
                <div class="col-md-2">
                  <label>Telefone</label>
                  <input type="text" class="form-control" v-mask="'(99) 9999-9999'" v-model="paciente.telefone" />
                </div>
                <div class="col-md-2">
                  <label><span class="required">*</span> Celular</label>
                  <input type="text" class="form-control" v-mask="'(99) 99999-9999'" v-model="paciente.celular" />
                </div>
                <div class="col-md-2">
                  <label><span class="required">*</span> RG</label>
                  <input type="text" class="form-control" v-model="paciente.rg" v-mask="'99.999.999-9'" />
                </div>
                <div class="col-md-2">
                  <label> <span class="required">*</span> Sexo: </label>
                  <select class="form-control" v-model="paciente.sexo">
                    <option value="Masculino">Masculino</option>
                    <option value="Feminino">Feminino</option>
                  </select>
                </div>
                <div class="col-md-2">
                  <label><span class="required">*</span> Estrangeiro?</label>

                  <br />
                  <label class="btn btn-primary" v-bind:class="{ disabled: !paciente.estrangeiro }" style="
                      cursor: pointer;
                      padding: 10px 0;
                      width: 49%;
                      margin-right: 1%;
                    ">
                    <input type="radio" name="objeto" :value="true" v-model="paciente.estrangeiro" class="d-none" />
                    Sim
                  </label>
                  <label class="btn btn-primary" v-bind:class="{ disabled: paciente.estrangeiro }" style="
                      cursor: pointer;
                      padding: 10px 0;
                      width: 49%;
                      margin-right: 1%;
                    ">
                    <input type="radio" name="objeto" v-model="paciente.estrangeiro" class="d-none" />
                    Não
                  </label>
                </div>
                <div class="col-md-2" v-if="!paciente.estrangeiro">
                  <label><span class="required">*</span> CPF</label>
                  <input type="text" class="form-control" v-mask="'999.999.999-99'" v-model="paciente.cpf" />
                </div>

                <div class="col-md-2">
                  <label><span class="required">*</span> Data de nascimento</label>
                  <input type="date" class="form-control" v-model="paciente.data_nascimento" />
                </div>

                <div class="col-md-2">
                  <label><span class="required">*</span> Cartão SUS</label>
                  <input type="text" class="form-control" v-model="paciente.cartao_sus" />
                </div>
                <div class="col-md-3">
                  <label><span class="required">*</span> Possui convênio?</label>

                  <br />
                  <label class="btn btn-primary" v-bind:class="{ disabled: !paciente.possui_convenio }" style="
                      cursor: pointer;
                      padding: 10px 0;
                      width: 49%;
                      margin-right: 1%;
                    ">
                    <input type="radio" name="objeto" :value="true" v-model="paciente.possui_convenio" class="d-none" />
                    Sim
                  </label>
                  <label class="btn btn-primary" v-bind:class="{ disabled: paciente.possui_convenio }" style="
                      cursor: pointer;
                      padding: 10px 0;
                      width: 49%;
                      margin-right: 1%;
                    ">
                    <input type="radio" name="objeto" v-model="paciente.possui_convenio" class="d-none" />
                    Não
                  </label>
                </div>

                <div class="col-md-4" v-if="paciente.possui_convenio">
                  <label><span class="required">*</span> Convênio</label>
                  <v-select :options="convenios" :reduce="(convenios) => convenios.id" :label="'nome'"
                    v-model="paciente.convenio_id" class="vselect" v-on:input="getPlanoConvenio()"></v-select>
                </div>
                <div class="col-md-4" v-if="paciente.possui_convenio">
                  <label><span class="required">*</span> Plano</label>
                  <v-select :options="planos" :reduce="(planos) => planos.id" :label="'nome'"
                    v-model="paciente.plano_id" class="vselect"></v-select>
                </div>
                <div class="col-md-4" v-if="paciente.possui_convenio">
                  <label><span class="required">*</span> Cartão convênio</label>
                  <input type="text" class="form-control" v-model="paciente.cartao_convenio" />
                </div>

                <div class="col-md-3">
                  <label><span class="required">*</span> CEP</label>
                  <input type="text" v-model="paciente.cep" class="form-control" v-on:keyup="buscar"
                    v-mask="'99999-999'" />
                </div>
                <div class="col-md-4">
                  <label> <span class="required">*</span> Logradouro</label>
                  <input type="text" v-model="paciente.logradouro" class="form-control" />
                </div>
                <div class="col-md-2">
                  <label><span class="required">*</span> Nº</label>
                  <input type="text" id="inputNumero" v-model="paciente.numero" class="form-control" />
                </div>
                <div class="col-md-3">
                  <label>Complemento</label>
                  <input type="text" v-model="paciente.complemento" class="form-control" />
                </div>
                <div class="col-md-3">
                  <label><span class="required">*</span> Bairro</label>
                  <input type="text" v-model="paciente.bairro" class="form-control" />
                </div>
                <div class="col-md-4">
                  <label><span class="required">*</span> Cidade</label>
                  <input type="text" v-model="paciente.cidade" class="form-control" />
                </div>
                <div class="col-md-2">
                  <label><span class="required">*</span> Estado</label>
                  <input type="text" v-model="paciente.estado" class="form-control" />
                </div>
                <div class="col-md-4">
                  <label>Empresa</label>
                  <v-select :options="empresas" :reduce="(empresas) => empresas.id" :label="'nome_fantasia'"
                    v-model="paciente.empresa_id" class="vselect"></v-select>
                </div>
                <div class="col-md-3">
                  <label>Prioridade</label>
                  <v-select :options="prioridades" :reduce="(prioridades) => prioridades.id" :label="'nome'"
                    v-model="paciente.prioridade_id" class="vselect"></v-select>
                </div>
                <!-- <div class="col-md-3">
                  <label>Alergias</label>
                  <input
                    type="text"
                    v-model="paciente.alergias"
                    class="form-control"
                  />
                </div> -->
                <div class="form-group col-md-4 mt-1">
                  <label for="foto"> Foto do Perfil </label>
                  <div class="user-img">
                    <img v-if="previewImage" :src="previewImage" class="photo" />
                    <img v-else class="photo" />
                  </div>
                  <input type="file" class="" id="foto" accept="image/*" name="foto" @change="uploadImage"
                    placeholder />
                </div>

                <div class="form-group col-md-4 mt-1">
                  <label for="">Tirar foto</label>
                  <br>
                  <button class="btn btn-add" @click="startCamera" v-if="!isCameraOpen">
                    Abrir câmera <i class="fas fa-solid fa-camera"></i>
                  </button>
                </div>

                <div class="form-group col-md-4 mt-1" v-if="isCameraOpen">
                  <video ref="video" width="640" height="480" autoplay></video>
                  <button @click="takePicture">Tirar foto</button>
                  <canvas ref="canvas" width="640" height="480"></canvas>

                </div>
                <img :src="imageUrl" v-if="imageUrl" />
                <!-- <div>
                  <video ref="video" width="640" height="480" autoplay></video>
                  <button @click="takePicture">Tirar foto</button>
                  <canvas ref="canvas" width="640" height="480"></canvas>
                  <img :src="imageUrl" v-if="imageUrl" />
                </div> -->

                <div class="col-md-12 text-right">
                  <hr />
                  <router-link to="/pacientes" class="btn btn-back mr-2">
                    <i class="fa fa-arrow-left"></i> Voltar
                  </router-link>
                  <a class="btn btn-add" @click="save">
                    Salvar
                    <i class="fa fa-save"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      paciente: {
        nome_completo: "",
        email: "",
        celular: "",
        rg: "",
        sexo: "",
        cpf: "",
        cartao_sus: "",
        cep: "",
        logradouro: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        foto: "",
        cpf: "",
        estrangeiro: ""
      },
      empresas: [],
      convenios: [],
      planos: [],
      prioridades: [],
      previewImage: null,
      imageUrl: null,
      isCameraOpen: false,
    };
  },
  computed: {},

  methods: {
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let foto = document.getElementById("foto");

      fd.append("nome_completo", self.paciente.nome_completo);
      fd.append("rg", self.paciente.rg);
      fd.append("cpf", self.paciente.cpf);
      fd.append("cep", self.paciente.cep);
      fd.append("email", self.paciente.email);
      fd.append("celular", self.paciente.celular);
      fd.append("telefone", self.paciente.telefone);
      fd.append("logradouro", self.paciente.logradouro);
      fd.append("numero", self.paciente.numero);
      fd.append(
        "complemento",
        self.paciente.complemento ? self.paciente.complemento : ""
      );
      fd.append("bairro", self.paciente.bairro);
      fd.append("cidade", self.paciente.cidade);
      fd.append("estado", self.paciente.estado);
      fd.append("cartao_sus", self.paciente.cartao_sus);
      fd.append("empresa_id", self.paciente.empresa_id);
      fd.append(
        "convenio_id",
        self.paciente.convenio_id ? self.paciente.convenio_id : ""
      );
      fd.append(
        "cartao_convenio",
        self.paciente.cartao_convenio ? self.paciente.cartao_convenio : ""
      );
      fd.append(
        "possui_convenio",
        self.paciente.possui_convenio ? self.paciente.possui_convenio : ""
      );
      fd.append("data_nascimento", self.paciente.data_nascimento);
      fd.append(
        "plano_id",
        self.paciente.plano_id ? self.paciente.plano_id : ""
      );
      fd.append("sexo", self.paciente.sexo);
      fd.append("prioridade_id", self.paciente.prioridade_id ? self.paciente.prioridade_id : "");
      fd.append("estrangeiro", self.paciente.estrangeiro ? self.paciente.estrangeiro : "");

      fd.append("_method", "POST");

      fd.append("foto", foto.files[0] ? foto.files[0] : "");

      if (self.paciente.id) {
        fd.append("id", self.paciente.id);
        fd.append("_method", "PUT");
      }

      return fd;
    },
    save: function () {
      const self = this;
      let api = self.$store.state.api + "pacientes";

      let fd = self.makeFormData();

      if (self.paciente.id) {
        api += "/" + self.paciente.id;
        self.paciente._method = "PUT";
      }

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/pacientes");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    buscar: function () {
      var self = this;

      self.naoLocalizado = false;

      if (/^[0-9]{5}-[0-9]{3}$/.test(this.paciente.cep)) {
        $.getJSON(
          "https://viacep.com.br/ws/" + this.paciente.cep + "/json/",
          function (endereco) {
            if (endereco.erro) {
              self.endereco = {};
              $("#inputNumero").focus();
              self.naoLocalizado = true;
              return;
            }
            self.endereco = endereco;
            self.paciente.logradouro = self.endereco.logradouro;
            self.paciente.bairro = self.endereco.bairro;
            self.paciente.estado = self.endereco.uf;
            self.paciente.cidade = self.endereco.localidade;
            $("#inputNumero").focus();
          }
        );
      }
    },

    getPacientes: function (id) {
      const self = this;
      const api = self.$store.state.api + "pacientes/" + id;

      axios
        .get(api)
        .then((response) => {
          self.paciente = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEmpresas: function () {
      const self = this;
      const api = self.$store.state.api + "empresas/";

      axios
        .get(api)
        .then((response) => {
          self.empresas = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getPrioridades: function () {
      const self = this;
      const api = self.$store.state.api + "prioridades/";

      axios
        .get(api)
        .then((response) => {
          self.prioridades = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getConvenios: function () {
      const self = this;
      const api = self.$store.state.api + "convenios?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.convenios = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getPlanos: function () {
      const self = this;
      const api = self.$store.state.api + "planos?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.planos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getPlanoConvenio() {
      const self = this;
      let api =
        self.$store.state.api +
        "planos?orderByAsc=nome&paginated=false&convenio_id=" +
        self.paciente.convenio_id;

      axios
        .get(api)
        .then((response) => {
          self.planos = response.data.data;
          self.paciente.plano_id = "";
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
        console.log(this.previewImage);
      };
    },

    async startCamera() {
      this.isCameraOpen = true;
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        const video = this.$refs.video;
        if (video) {
          video.srcObject = stream;
          this.isCameraOpen = true;
        }
      } catch (error) {
        console.error(error);
      }
    },

    takePicture() {
      // Capturar um quadro de vídeo e desenhar no canvas
      this.$refs.canvas
        .getContext("2d")
        .drawImage(
          this.$refs.video,
          0,
          0,
          this.$refs.canvas.width,
          this.$refs.canvas.height
        );
      this.isCameraOpen = false;
      // Obter a URL da imagem do canvas e atualizar o estado
      this.imageUrl = this.$refs.canvas.toDataURL("image/png");
    },
  },
  mounted: function () {
    const self = this;
    self.getEmpresas();
    self.getConvenios();
    self.getPlanos();
    self.getPrioridades();
    $("#nomeCompleto").focus();




    let id = self.$route.params.id;
    if (id) {
      self.getPacientes(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>