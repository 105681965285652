import Crud from '../views/Especialidade/Crud'
import Entity from '../views/Especialidade/Add'

let routes = {
    path: '/especialidades',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/especialidades/add',
            name: 'NewEspecialidade',
            component: Entity,
            meta: {
                pageTitle: 'Especialidades',
                breadcrumb: [
                    {
                        text: 'Especialidades',
                        to: '/especialidades',
                        active: false,
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/especialidades/edit/:id?',
            name: 'EditEspecialidade',
            component: Entity,
            meta: {
                pageTitle: 'Especialidades',
                breadcrumb: [
                    {
                        text: 'Especialidades',
                        to: '/especialidades',
                        active: false,
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/especialidades',
            name: 'list-especialidades',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Especialidades',
                breadcrumb: [
                    {
                        text: 'Especialidades',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
