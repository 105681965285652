<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-3">
                  <label><span>*</span> Convênio</label>
                  <input v-model="convenio.nome_fantasia" id="nomeFantasia" class="form-control" type="text" />
                </div>
                <div class="col-md-3">
                  <label><span>*</span> Registro ANS</label>
                  <input v-model="convenio.ans" class="form-control" type="text" />
                </div>
                <div class="col-md-3">
                  <label><span>*</span> Padrão SADT</label>
                  <input v-model="convenio.sadt" class="form-control" type="text" />
                </div>
                <div class="col-md-3">
                  <label><span>*</span> Empresa</label>
                  <v-select :options="empresas" :reduce="empresas => empresas.id" :label="'nome_fantasia'"
                    v-model="convenio.empresa_id" class="vselect"></v-select>
                </div>
                <div class="col-md-12 text-right">
                  <hr />
                  <router-link to="/convenios" class="btn btn-back mr-1">
                    <i class="fa fa-arrow-left" />
                    Voltar
                  </router-link>
                  <a class="btn btn-add" @click="save">
                    Salvar
                    <i class="fa fa-save" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      convenio: {
        nome_fantasia: "",
        ans: "",
        sadt: "",
        empresa_id: "",
      },
      empresas: [],
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "convenios";

      if (self.convenio.id) {
        api += "/" + self.convenio.id;
        self.convenio._method = "PUT";
      }

      axios
        .post(api, self.convenio)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/convenios");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },


    getConvenios: function (id) {
      const self = this;
      const api = self.$store.state.api + "convenios/" + id;

      axios
        .get(api)
        .then((response) => {
          self.convenio = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEmpresas: function () {
      const self = this;
      const api = self.$store.state.api + "empresas/";

      axios
        .get(api)
        .then((response) => {
          self.empresas = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    self.getEmpresas();
    $("#nomeFantasia").focus();

    let id = self.$route.params.id;
    if (id) {
      self.getConvenios(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
