import Crud from '../views/Profissional/Crud'
import Entity from '../views/Profissional/Add'
import View from '../views/MedicoHorario/View'

let routes = {
    path: '/profissionais',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/profissionais/add',
            name: 'NewProfissional',
            component: Entity,
            meta: {
                pageTitle: 'Profissionais',
                breadcrumb: [
                    {
                        text: 'Profissionais',
                        to: '/profissionais',
                        active: false,
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/profissionais/edit/:id?',
            name: 'EditProfissional',
            component: Entity,
            meta: {
                pageTitle: 'Profissionais',
                breadcrumb: [
                    {
                        text: 'Profissionais',
                        to: '/profissionais',
                        active: false,
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },


        {
            path: '/profissionais',
            name: 'list-profissionais',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Profissionais',
                breadcrumb: [
                    {
                        text: 'Profissionais',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
