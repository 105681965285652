<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-nome">
                {{ itens_checklists.id ? "Editar" : "Novo" }} Item
              </div>
            </div>
            <div class="card-body">
              <hr />
              <br />
              <div class="row">
                <div class="col-md-12">
                  <form action>
                    <div class="form-row">
                      <div class="form-group col-md-6">
                        <label for="Name">
                          <span class="required">*</span> Nome:
                        </label>
                        <input
                          autocomplete="new-password"
                          type="text"
                          id="Name"
                          class="form-control"
                          v-model="itens_checklists.nome"
                          placeholder=""
                        />
                      </div>
                      <div class="col-md-6">
                        <label>Checklist</label>
                        <v-select
                          :options="checklists"
                          :reduce="(checklists) => checklists.id"
                          :label="'nome'"
                          v-model="itens_checklists.checklist_id"
                          class="vselect"
                        ></v-select>
                      </div>
                    </div>
                    <br />
                    <span class="required_fields mt-2">
                      <span class="required">*</span>
                      <strong>Campos obrigatórios</strong>
                    </span>
                    <div class="block text-right">
                      <router-link to="/itens_checklists" class="btn btn-back">
                        <i class="fa fa-arrow-left"></i> Voltar
                      </router-link>
                      <a class="btn btn-add ml-1" @click="save">
                        Salvar
                        <i class="fa fa-save"></i>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      itens_checklists: {
        nome: "",
        checklist_id: "",
      },
      checklists: [],
      errors: undefined,
      previewImage: null,
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "itens_checklists";

      let fd = self.itens_checklists;

      if (self.itens_checklists.id) {
        fd._method = "PUT";
        api += "/" + self.itens_checklists.id;
      }

      axios
        .post(api, fd)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/itens_checklists");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getChecklists: function () {
      const self = this;
      const api = self.$store.state.api + "checklists/";

      axios
        .get(api)
        .then((response) => {
          self.checklists = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getItens: function (id) {
      const self = this;
      const api = self.$store.state.api + "itens_checklists/" + id;

      axios
        .get(api)
        .then((response) => {
          self.itens_checklists = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;

    self.getChecklists();

    let id = self.$route.params.id;
    if (id) {
      self.getItens(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
</style>