import Vue from 'vue'
import VueRouter from 'vue-router'

import Agendamento from './agendamentos'
import Atestado from './atestados'
import Cid from './cids'
import Convenio from './convenios'
import Empresa from './empresas'
import Especialidade from './especialidades'
import Exame from './exames'
import Laboratorio from './laboratorios'
import Laudo from './laudos'
import Medicamento from './medicamentos'
import MedicoHorario from './medico_horarios'
import Paciente from './pacientes'
import Plano from './planos'
import Prescricao from './prescricoes'
import PrincipioAtivo from './principio_ativo'
import Procedimento from './procedimentos'
import Profissional from "./profissionais"
import Prontuario from './prontuarios'
import Solicitacoes from './solicitacao_exames'
import TipoConselho from "./tipo_conselhos"
import TipoProfissional from "./tipo_profissionais"
import TipoReceita from "./tipo_receitas"
import User from './user'
import Prioridade from './prioridades'
import Checklist from './checklists'
import ItensChecklist from './itens_checklists'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    Agendamento,
    Atestado,
    Cid,
    Convenio,
    Empresa,
    Especialidade,
    Exame,
    Laboratorio,
    Laudo,
    Medicamento,
    MedicoHorario,
    Paciente,
    Plano,
    Prescricao,
    PrincipioAtivo,
    Procedimento,
    Profissional,
    Prontuario,
    Solicitacoes,
    TipoConselho,
    TipoProfissional,
    TipoReceita,
    User,
    Prioridade,
    Checklist,
    ItensChecklist,

    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const terms = localStorage.getItem('accept_terms');
  const authenticated = localStorage.getItem('token');
  let to_splited = to.path.toString().split('/')
  to_splited = to_splited.filter(part => part !== '')

  if (
    !authenticated &&
    (
      !(['/login'].includes(to.path.toString())) &&
      !(['public'].includes(to_splited[0]))
    )
  ) {
    next('/login');

    return true;
  }

  next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
