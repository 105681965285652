<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <label><span>*</span> Nome</label>
                  <input
                    type="text"
                    id="especialidade"
                    class="form-control"
                    v-model="especialidade.nome"
                  />
                </div>
                <div class="col-md-4">
                  <label><span>*</span> Conselho</label>
                  <v-select :options="tipo_conselhos" :reduce="tipo_conselhos => tipo_conselhos.id" :label="'nome'"
                    v-model="especialidade.tipo_conselho_id" class="vselect"></v-select>
                </div>
                <div class="col-md-4">
                  <label><span>*</span> Empresa</label>
                  <v-select
                    :options="empresas"
                    :reduce="(empresas) => empresas.id"
                    :label="'nome_fantasia'"
                    v-model="especialidade.empresa_id"
                    class="vselect"
                  ></v-select>
                </div>
                <div class="col-md-12 text-right">
                  <hr />
                  <router-link to="/especialidades" class="btn btn-back mr-1">
                    <i class="fa fa-arrow-left"></i> Voltar
                  </router-link>
                  <a class="btn btn-add" @click="save">
                    Salvar
                    <i class="fa fa-save"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      especialidade: {
        nome: "",
        tipo_conselho_id: "",
        empresa_id: "",
      },
      empresas: [],
      tipo_conselhos: [],
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "especialidades";

      if (self.especialidade.id) {
        api += "/" + self.especialidade.id;
        self.especialidade._method = "PUT";
      }

      axios
        .post(api, self.especialidade)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/especialidades");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEspecialidades: function (id) {
      const self = this;
      const api = self.$store.state.api + "especialidades/" + id;

      axios
        .get(api)
        .then((response) => {
          self.especialidade = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEmpresas: function (id) {
      const self = this;
      const api = self.$store.state.api + "empresas/";

      axios
        .get(api)
        .then((response) => {
          self.empresas = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getConselhos: function (id) {
      const self = this;
      const api = self.$store.state.api + "tipo_conselhos/";

      axios
        .get(api)
        .then((response) => {
          self.tipo_conselhos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    self.getEmpresas();
    self.getConselhos();
    $("#especialidade").focus();

    let id = self.$route.params.id;
    if (id) {
      self.getEspecialidades(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>