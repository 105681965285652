import Crud from '../views/Atestado/Crud'
import Entity from '../views/Atestado/Add'

let routes = {
    path: '/atestados',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/atestados/add',
            name: 'NewAtestado',
            component: Entity,
            meta: {
                pageTitle: 'Atestados',
                breadcrumb: [
                    {
                        text: 'Atestados',
                        to: '/atestados',
                        active: false,
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/atestados/edit/:id?',
            name: 'EditAtestado',
            component: Entity,
            meta: {
                pageTitle: 'Atestados',
                breadcrumb: [
                    {
                        text: 'Atestados',
                        to: '/atestados',
                        active: false,
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/atestados',
            name: 'list-atestados',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Atestados',
                breadcrumb: [
                    {
                        text: 'Atestados',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
