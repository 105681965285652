<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                    <label>Nome completo</label>
                    <v-select :options="pacientes" :reduce="pacientes => pacientes.id" :label="'nome_completo'"
                    v-model="solicitacaoexame.paciente_id" class="vselect"></v-select>
                </div>
                <div class="col-md-6">
                    <label>Médico responsável pela solicitação do Exame</label>
                    <v-select :options="profissionais" :reduce="profissionais => profissionais.id" :label="'nome_profissional'"
                    v-model="solicitacaoexame.profissional_id" class="vselect"></v-select>
                </div>
                
                <div class="col-md-6">
                  <label>Procedimento realizado</label>
                  <v-select :options="procedimentos" :reduce="procedimentos => procedimentos.id" :label="'nome_procedimento'"
                    v-model="solicitacaoexame.procedimento_id" class="vselect"></v-select>
                </div>
                <div class="col-md-6">
                  <label>Data da solicitação</label>
                  <input type="datetime-local" class="form-control" v-model="solicitacaoexame.data_solicitado" />
                </div>
                <div class="col-md-12">
                  <label>Descrição do Procedimento</label>
                  <textarea cols="30" rows="3" class="form-control" v-model="solicitacaoexame.descricao_do_procedimento"></textarea>
                </div>
               
              
                <div class="col-md-12 text-right">
                  <hr>
                  <router-link to="/solicitacao_exames" class="btn btn-back mr-1">
                    <i class="fa fa-arrow-left"></i> Voltar
                  </router-link>
                  <a class="btn btn-add" @click="save">
                    Salvar
                    <i class="fa fa-save"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      solicitacaoexame: {},
      pacientes: [],
      procedimentos: [],
      profissionais: [],
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "solicitacao_exames";

      if (self.solicitacaoexame.id) {
        api += '/' + self.solicitacaoexame.id;
        self.solicitacaoexame._method = 'PUT';
      }

      axios
        .post(api, self.solicitacaoexame)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/solicitacao_exames");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getSolicitacoes: function (id) {
      const self = this;
      const api = self.$store.state.api + "solicitacao_exames/" + id;

      axios
        .get(api)
        .then((response) => {
          self.solicitacaoexame = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getPacientes: function () {
      const self = this;
      const api = self.$store.state.api + "pacientes/"

      axios
        .get(api)
        .then((response) => {
          self.pacientes = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getProcedimentos: function () {
      const self = this;
      const api = self.$store.state.api + "procedimentos/"

      axios
        .get(api)
        .then((response) => {
          self.procedimentos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getProfissionais: function () {
      const self = this;
      const api = self.$store.state.api + "profissionais/"

      axios
        .get(api)
        .then((response) => {
          self.profissionais = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    self.getPacientes();
    self.getProcedimentos();
    self.getProfissionais();
    
    let id = self.$route.params.id;
    if (id) {
      self.getSolicitacoes(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}