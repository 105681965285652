import Crud from '../views/Prioridade/Crud'
import Entity from '../views/Prioridade/Add'

let routes = {
    path: '/prioridades',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/prioridades/add',
            name: 'NewPrioridade',
            component: Entity,
            meta: {
                pageTitle: 'Prioridades',
                breadcrumb: [
                    {
                        text: 'Prioridades',
                        to: '/prioridades',
                        active: false,
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/prioridades/edit/:id?',
            name: 'EditPrioridade',
            component: Entity,
            meta: {
                pageTitle: 'Prioridades',
                breadcrumb: [
                    {
                        text: 'Prioridades',
                        to: '/prioridades',
                        active: false,
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/prioridades',
            name: 'list-prioridades',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Prioridades',
                breadcrumb: [
                    {
                        text: 'Prioridades',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
