<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <label><span>*</span> Laboratório</label>
                  <input
                    v-model="laboratorio.nome"
                    id="nomeFantasia"
                    class="form-control"
                    type="text"
                  />
                </div>
                
              
                <div class="col-md-3">
                  <label><span>*</span> CNPJ</label>
                  <input
                    v-model="laboratorio.cnpj"
                    v-mask="'99.999.999/9999-99'"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-3">
                  <label><span>*</span> CNES</label>
                  <input
                    v-model="laboratorio.cnes"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-2">
                  <label><span class="required">*</span> CEP</label>
                  <input
                    type="text"
                    v-model="laboratorio.cep"
                    class="form-control"
                    v-on:keyup="buscar"
                    v-mask="'99999-999'"
                  />
                </div>
                <div class="col-md-4">
                  <label> <span class="required">*</span> Logradouro</label>
                  <input
                    type="text"
                    v-model="laboratorio.logradouro"
                    class="form-control"
                  />
                </div>
                <div class="col-md-2">
                  <label><span class="required">*</span> Nº</label>
                  <input
                  id="inputNumero"
                    type="text"
                    v-model="laboratorio.numero"
                    class="form-control"
                  />
                </div>
                <div class="col-md-4">
                  <label>Complemento</label>
                  <input
                    type="text"
                    v-model="laboratorio.complemento"
                    class="form-control"
                  />
                </div>
                <div class="col-md-3">
                  <label><span class="required">*</span> Bairro</label>
                  <input
                    type="text"
                    v-model="laboratorio.bairro"
                    class="form-control"
                  />
                </div>
                <div class="col-md-3">
                  <label><span class="required">*</span> Cidade</label>
                  <input
                    type="text"
                    v-model="laboratorio.cidade"
                    class="form-control"
                  />
                </div>
                <div class="col-md-2">
                  <label><span class="required">*</span> Estado</label>
                  <input
                    type="text"
                    v-model="laboratorio.estado"
                    class="form-control"
                  />
                </div>
                <div class="col-md-4">
                  <label><span class="required">*</span> Telefone Corporativo</label>
                  <input
                    v-model="laboratorio.telefone"
                    v-mask="'(99).99999-9999'"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-4">
                  <label><span class="required">*</span> Email Comporativo</label>
                  <input
                    v-model="laboratorio.email"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-4">
                  <label><span class="required">*</span> Empresa</label>
                  <v-select
                    v-model="laboratorio.empresa_id"
                    class="vselect"
                    :options="empresas"
                    :reduce="(empresas) => empresas.id"
                    :label="'nome_fantasia'"
                  />
                </div>
                <div class="col-md-12 text-right">
                  <hr />
                  <router-link to="/laboratorios" class="btn btn-back mr-1">
                    <i class="fa fa-arrow-left"></i> Voltar
                  </router-link>
                  <a class="btn btn-add" @click="save">
                    Salvar
                    <i class="fa fa-save"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      empresas: [],
      laboratorio: {
        nome: "",
        cnpj: "",
        cnes: "",
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        estado: "",
        telefone: "",
        email: "",
        empresa_id: "",
      },
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "laboratorios";

      if (self.laboratorio.id) {
        api += "/" + self.laboratorio.id;
        self.laboratorio._method = "PUT";
      }

      axios
        .post(api, self.laboratorio)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/laboratorios");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    buscar: function () {
      var self = this;

      self.naoLocalizado = false;

      if (/^[0-9]{5}-[0-9]{3}$/.test(this.laboratorio.cep)) {
        $.getJSON(
          "https://viacep.com.br/ws/" + this.laboratorio.cep + "/json/",
          function (endereco) {
            if (endereco.erro) {
              self.endereco = {};
              $("#inputNumero").focus();
              self.naoLocalizado = true;
              return;
            }
            self.endereco = endereco;
            self.laboratorio.logradouro = self.endereco.logradouro;
            self.laboratorio.complemento = self.endereco.complemento;
            self.laboratorio.bairro = self.endereco.bairro;
            self.laboratorio.cidade = self.endereco.localidade;
            self.laboratorio.estado = self.endereco.uf;
            $("#inputNumero").focus();
          }
        );
      }
    },
    getLaboratorios: function (id) {
      const self = this;
      const api = self.$store.state.api + "laboratorios/" + id;

      axios
        .get(api)
        .then((response) => {
          self.laboratorio = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEmpresas: function () {
      const self = this;
      const api = self.$store.state.api + "empresas/";

      axios
        .get(api)
        .then((response) => {
          self.empresas = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    self.getEmpresas();
    $("#nomeFantasia").focus();

    let id = self.$route.params.id;
    if (id) {
      self.getLaboratorios(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
