import Crud from '../views/Prescricao/Crud'
import Entity from '../views/Prescricao/Add'

let routes = {
    path: '/prescricoes',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/prescricoes/add',
            name: 'NewPrescricoes',
            component: Entity,
            meta: {
                pageTitle: 'Prescrições',
                breadcrumb: [
                    {
                        text: 'Prescrições',
                        to: '/prescricoes',
                        active: false,
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/prescricoes/edit/:id?',
            name: 'EditPrescricoes',
            component: Entity,
            meta: {
                pageTitle: 'Prescrições',
                breadcrumb: [
                    {
                        text: 'Prescrições',
                        to: '/prescricoes',
                        active: false,
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/prescricoes',
            name: 'list-prescricoes',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Prescrições',
                breadcrumb: [
                    {
                        text: 'Prescrições',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
