import Crud from '../views/TipoReceita/Crud'
import Entity from '../views/TipoReceita/Add'

let routes = {
    path: '/tipo_receitas',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/tipo_receitas/add',
            name: 'NewTipoReceita',
            component: Entity,
            meta: {
                pageTitle: 'Tipos de Receita',
                breadcrumb: [
                    {
                        text: 'Tipos de Receita',
                        to: '/tipo_receitas',
                        active: false,
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/tipo_receitas/edit/:id?',
            name: 'EditTipoReceita',
            component: Entity,
            meta: {
                pageTitle: 'Tipos de Receita',
                breadcrumb: [
                    {
                        text: 'Tipos de Receita',
                        to: '/tipo_receitas',
                        active: false,
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/tipo_receitas',
            name: 'list-tipo_receitas',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Tipo de Receita',
                breadcrumb: [
                    {
                        text: 'Tipos de Receita',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
