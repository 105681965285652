<template>
    <div>
        <div class="main-content">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                  <label>Profissional</label>
                                  <v-select
                                    :options="profissionais"
                                    :reduce="(profissionais) => profissionais.id"
                                    :label="'nome'"
                                    v-model="medico_horario.profissional_id"
                                    class="vselect"
                                  ></v-select>
                                </div>
                                <div class="col-md-4">
                                    <label>Dia da semana</label>
                                    <select class="form-control" v-model="medico_horario.dia_da_semana">
                                      <option class="form-control" value="Segunda-Feira">Segunda-Feira</option>
                                      <option class="form-control" value="Terça-Feira">Terça-Feira</option>
                                      <option class="form-control" value="Quarta-Feira">Quarta-Feira</option>
                                      <option class="form-control" value="Quinta-Feira">Quinta-Feira</option>
                                      <option class="form-control" value="Sexta-Feira">Sexta-Feira</option>
                                      <option class="form-control" value="Sábado">Sábado</option>
                                      <option class="form-control" value="Domingo">Domingo</option>
                                    </select>
                                </div>
                                <div class="col-md-4">
                                    <label>Horário de início</label>
                                    <input type="time" class="form-control" v-model="medico_horario.hora_inicio" />
                                </div>
                                <div class="col-md-4">
                                    <label>Horário de encerramento</label>
                                    <input type="time" class="form-control" v-model="medico_horario.hora_fim" />
                                </div>
                                <div class="col-md-12 text-right">
                                    <hr>
                                    <router-link to="/medico_horarios" class="btn btn-back mr-1">
                                    <i class="fa fa-arrow-left"></i> Voltar
                                    </router-link>
                                    <a class="btn btn-add" @click="save">
                                    Salvar
                                    <i class="fa fa-save"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      medico_horario: {},
      profissionais: [],
    };
  },
  computed: {},
  methods: {

    // mFDGradeHoraria: function () {
    //   const self = this;
    //   let fd = new FormData();

    //   fd.append("profissional_id", self.medico_horario.profissional_id);
    //   fd.append("dia_da_semana", self.medico_horario.dia_da_semana);
    //   fd.append("hora_inicio", self.medico_horario.hora_inicio);
    //   fd.append("hora_fim", self.medico_horario.hora_fim);
      

    //   return fd;
    // },
    save: function () {
      const self = this;
      let api = self.$store.state.api + "medico_horarios";
      // \
      

      if(self.medico_horario.id){
        api += '/' + self.medico_horario.id;
        self.medico_horario._method = 'PUT';
      }

      axios
        .post(api, self.medico_horario)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/profissionais");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getMedicoHorario: function (id) {
      const self = this;
      const api = self.$store.state.api + "medico_horarios/" + id;

      axios
        .get(api)
        .then((response) => {
          self.medico_horario = response.data.data[0];
          
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getProfissionais: function () {
      const self = this;
      const api = self.$store.state.api + "profissionais/";

      axios
        .get(api)
        .then((response) => {
          self.profissionais = response.data.data;
          
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    self.getProfissionais();

    let id = self.$route.params.id;
    if (id) {
      self.getMedicoHorario(id);
      
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>