<template>
  <div>
    <div class="main-content">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <label><span>*</span> Nome</label>
                  <input
                    v-model="profissional.nome"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-3">
                  <label for="cpf"><span>*</span> CPF:</label>
                  <input
                    v-model="profissional.cpf"
                    v-mask="'999.999.999-99'"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-3">
                  <label for="rg"><span>*</span> RG:</label>
                  <input
                    v-model="profissional.rg"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-2">
                  <label for="rg"><span>*</span> Email Institucional:</label>
                  <input
                    v-model="profissional.email"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-2">
                  <label><span>*</span> Tipo Profissional</label>
                  <v-select
                    v-model="profissional.tipo_profissional_id"
                    :options="tipo_profissionais"
                    :reduce="(tipo_profissionais) => tipo_profissionais.id"
                    :label="'nome'"
                    class="vselect"
                  />
                </div>
                <div class="col-md-4">
                  <label><span>*</span> Tipo Conselho</label>
                  <v-select
                    :options="tipo_conselhos"
                    :reduce="(tipo_conselhos) => tipo_conselhos.id"
                    :label="'nome'"
                    v-model="profissional.tipo_conselho_id"
                    class="vselect"
                  />
                </div>
                <div class="col-md-2">
                  <label for="numero_conselho"><span>*</span> Número do Conselho:</label>
                  <input
                    v-model="profissional.conselho"
                    class="form-control"
                    type="text"
                  />
                </div>
                <div class="col-md-4">
                  <label for="especialidade_id"><span>*</span> Especialidade:</label>
                  <v-select
                    v-model="profissional.especialidade_id"
                    class="vselect"
                    :options="especialidades"
                    :reduce="(especialidades) => especialidades.id"
                    :label="'nome'"
                  />
                </div>
                <div class="col-md-8">
                  <label for="company_id"><span>*</span> Empresa:</label>
                  <v-select
                    v-model="profissional.empresa_id"
                    class="vselect"
                    :options="empresas"
                    :reduce="(empresas) => empresas.id"
                    :label="'nome_fantasia'"
                  />
                </div>
                <div class="col-md-4">
                  <label><span>*</span> CEP</label>
                  <input
                    type="text"
                    v-model="profissional.cep"
                    class="form-control"
                    v-on:keyup="buscar"
                    v-mask="'99999-999'"
                  />
                </div>
                <div class="col-md-8">
                  <label><span>*</span> Logradouro</label>
                  <input
                    type="text"
                    v-model="profissional.logradouro"
                    class="form-control"
                  />
                </div>
                <div class="col-md-4">
                  <label><span>*</span> Número</label>
                  <input
                    type="text"
                    v-model="profissional.numero"
                    class="form-control"
                  />
                </div>
                <div class="col-md-3">
                  <label>Complemento</label>
                  <input
                    type="text"
                    v-model="profissional.complemento"
                    class="form-control"
                  />
                </div>
                <div class="col-md-3">
                  <label><span>*</span> Bairro</label>
                  <input
                    type="text"
                    v-model="profissional.bairro"
                    class="form-control"
                  />
                </div>
                <div class="col-md-3">
                  <label><span>*</span> Cidade</label>
                  <input
                    type="text"
                    v-model="profissional.cidade"
                    class="form-control"
                  />
                </div>
                <div class="col-md-3">
                  <label><span>*</span> Estado</label>
                  <input
                    type="text"
                    v-model="profissional.estado"
                    class="form-control"
                  />
                </div>
                <div class="col-md-12 text-right">
                  <hr />
                  <router-link to="/profissionais" class="btn btn-back mr-1">
                    <i class="fa fa-arrow-left" />
                    Voltar
                  </router-link>
                  <a class="btn btn-add" @click="save">
                    Salvar
                    <i class="fa fa-save" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      profissional: {
        nome: "",
        cpf: "",
        rg: "",
        tipo_profissional_id: "",
        tipo_conselho_id: "",
        conselho: "",
        especialidade_id: "",
        empresa_id: "",
        cep: "",
        logradouro: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        estado: "",
      },
      especialidades: [],
      empresas: [],
      tipo_conselhos: [],
      tipo_profissionais: [],
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "profissionais";

      if (self.profissional.id) {
        api += "/" + self.profissional.id;
        self.profissional._method = "PUT";
      }

      axios
        .post(api, self.profissional)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/profissionais");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    buscar: function () {
      var self = this;

      self.naoLocalizado = false;

      if (/^[0-9]{5}-[0-9]{3}$/.test(this.profissional.cep)) {
        $.getJSON(
          "https://viacep.com.br/ws/" + this.profissional.cep + "/json/",
          function (endereco) {
            if (endereco.erro) {
              self.endereco = {};
              $("#inputNumero").focus();
              self.naoLocalizado = true;
              return;
            }
            self.endereco = endereco;
            self.profissional.logradouro = self.endereco.logradouro;
            self.profissional.complemento = self.endereco.complemento;
            self.profissional.bairro = self.endereco.bairro;
            self.profissional.cidade = self.endereco.localidade;
            self.profissional.estado = self.endereco.uf;
            $("#inputNumero").focus();
          }
        );
      }
    },

    getProfissional: function (id) {
      const self = this;
      const api = self.$store.state.api + "profissionais/" + id;

      axios
        .get(api)
        .then((response) => {
          self.profissional = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getConselhos: function () {
      const self = this;
      const api = self.$store.state.api + "tipo_conselhos/";

      axios
        .get(api)
        .then((response) => {
          self.tipo_conselhos = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getTipoProfissionais: function () {
      const self = this;
      const api = self.$store.state.api + "tipo_profissionais/";

      axios
        .get(api)
        .then((response) => {
          self.tipo_profissionais = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEspecialidades: function () {
      const self = this;
      const api = self.$store.state.api + "especialidades/";

      axios
        .get(api)
        .then((response) => {
          self.especialidades = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getEmpresas: function () {
      const self = this;
      const api = self.$store.state.api + "empresas/";

      axios
        .get(api)
        .then((response) => {
          self.empresas = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    self.getEmpresas();
    self.getConselhos();
    self.getTipoProfissionais();
    self.getEspecialidades();
    $("#nomeProfissional").focus();

    let id = self.$route.params.id;
    if (id) {
      self.getProfissional(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>
