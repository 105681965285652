import Crud from '../views/Exame/Crud'
import Entity from '../views/Exame/Add'

let routes = {
    path: '/exames',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/exames/add',
            name: 'NewExame',
            component: Entity,
            meta: {
                pageTitle: 'Exames',
                breadcrumb: [
                    {
                        text: 'Exames',
                        to: '/exames',
                        active: false,
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/exames/edit/:id?',
            name: 'EditExame',
            component: Entity,
            meta: {
                pageTitle: 'Exames',
                breadcrumb: [
                    {
                        text: 'Exames',
                        to: '/exames',
                        active: false,
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/exames',
            name: 'list-exames',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Exames',
                breadcrumb: [
                    {
                        text: 'Exames',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
