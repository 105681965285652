import Crud from '../views/MedicoHorario/Crud'
import Entity from '../views/MedicoHorario/Add'
import View from '../views/MedicoHorario/View'

let routes = {
    path: '/medico_horarios',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/medico_horarios/add',
            name: 'NewMedicoHorario',
            component: Entity,
            meta: {
                pageTitle: 'Grade Horária',
                breadcrumb: [
                    {
                        text: 'Grade Horária',
                        to: '/medico_horarios',
                        active: false,
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/medico_horarios/edit/:id?',
            name: 'EditMedicoHorario',
            component: Entity,
            meta: {
                pageTitle: 'Grade Horária',
                breadcrumb: [
                    {
                        text: 'Grade Horária',
                        to: '/medico_horarios',
                        active: false,
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },

        {
            path: '/medico_horarios/view/:id?',
            name: 'ViewMedicoHorario',
            component: View,
            meta: {
                pageTitle: 'Grade Horária',
                breadcrumb: [
                    {
                        text: 'Grade Horária',
                        to: '/medico_horarios',
                        active: false,
                    },
                    {
                        text: 'Grade Horária',
                        active: true,
                    }
                ],
            },
        },

        
        {
            path: '/medico_horarios',
            name: 'list-medico_horarios',
            component: Crud,
            meta: {
                pageTitle: 'Grade Horária dos Médicos',
                breadcrumb: [
                    {
                        text: 'Grade Horária',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
