import Crud from '../views/Laboratorio/Crud'
import Entity from '../views/Laboratorio/Add'

let routes = {
    path: '/laboratorios',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/laboratorios/add',
            name: 'NewLaboratorio',
            component: Entity,
            meta: {
                pageTitle: 'Laboratórios',
                breadcrumb: [
                    {
                        text: 'Laboratórios',
                        to: '/laboratorios',
                        active: false,
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/laboratorios/edit/:id?',
            name: 'EditLaboratorio',
            component: Entity,
            meta: {
                pageTitle: 'Laboratórios',
                breadcrumb: [
                    {
                        text: 'Laboratórios',
                        to: '/laboratorios',
                        active: false,
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/laboratorios',
            name: 'list-laboratorios',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Laboratórios',
                breadcrumb: [
                    {
                        text: 'Laboratórios',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
