import Crud from '../views/ItensChecklist/Crud'
import Entity from '../views/ItensChecklist/Add'


let routes = {
    path: '/itens_checklists',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/itens_checklists/add',
            name: 'NewItensChecklist',
            component: Entity,
            meta: {
                pageTitle: 'itens_checklists',
                breadcrumb: [
                    {
                        text: 'itens_checklists',
                        to: '/itens_checklists',
                        active: false,
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/itens_checklists/edit/:id?',
            name: 'EditItensChecklist',
            component: Entity,
            meta: {
                pageTitle: 'itens_checklists',
                breadcrumb: [
                    {
                        text: 'itens_checklists',
                        to: '/itens_checklists',
                        active: false,
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },

        {
            path: '/itens_checklists',
            name: 'list-itens-checklists',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Itens',
                breadcrumb: [
                    {
                        text: 'Itens',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
