import Crud from '../views/PrincipioAtivo/Crud'
import Entity from '../views/PrincipioAtivo/Add'

let routes = {
    path: '/principios_ativos',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/principios_ativos/add',
            name: 'NewPrincipioAtivo',
            component: Entity,
            meta: {
                pageTitle: 'Principios Ativos',
                breadcrumb: [
                    {
                        text: 'Principios Ativos',
                        to: '/principios_ativos',
                        active: false,
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/principios_ativos/edit/:id?',
            name: 'EditPrincipioAtivo',
            component: Entity,
            meta: {
                pageTitle: 'Principios Ativos',
                breadcrumb: [
                    {
                        text: 'Principios Ativos',
                        to: '/principios_ativos',
                        active: false,
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/principios_ativos',
            name: 'list-principios_ativos',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Principios Ativos',
                breadcrumb: [
                    {
                        text: 'Principios Ativos',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
