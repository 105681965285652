<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'profissionalCrud'"
          crudName="Profissional"
          newText="Novo Profissional"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="true"
          :enableEdit="true"
          :enableDelete="true"
          :enableView="false"
          :enableReset="false"
          :dataForm="dataForm"
          :extra="extra"
          @view="view"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      table: "profissionaisTable",
      showMessageImport: false,
      usersCrud: {},
      pages: [],
      url: "",
      columns: ["id", "nome", "tipo_profissional_id", "actions"],
      tableData: [
        "id",
        "nome",
        "cpf",
        "rg",
        "tipo_profissional_id",
        "conselho",
        "especialidade_id",
        "empresa_id",
        "tipo_conselho_id",
        "logradouro",
        "numero",
        "complemento",
        "bairro",
        "cidade",
        "estado",
        "cep",
      ],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: ["nome", "tipo_profissional_id"],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          nome: "Nome",
          tipo_profissional_id: "Tipo Profissional",
          actions: "Ações",
        },
        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {},
        templates: {
          company_id: function (h, row, index) {
            return row.empresa ? row.empresa.nome_fantasia : "Indisponível";
          },
          especialidade_id: function (h, row, index) {
            return row.especialidade
              ? row.especialidade.especialidade
              : "Indisponível";
          },
          id_conselho: function (h, row, index) {
            return row.tipoconselho
              ? row.tipoconselho.conselho
              : "Indisponível";
          },
          tipo_profissional_id: function (h, row, index) {
            return row.tipo_profissional
              ? row.tipo_profissional.nome
              : "Indisponível";
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = [
            "empresa",
            "especialidade",
            "tipoconselho",
            "tipo_profissional",
          ];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },

      extra: [
        {
          id: 1,
          label: "",
          class: "ml-1 btn btn-crud calendar",
          title: "Grade Horária",
          name: "view",
          icon: "fas fa-solid fa-calendar",
        },
      ],
      endPoint: "profissionais/",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    view(props, row, index) {
      const self = this;
      this.$router.push("/medico_horarios/view/" + props.row.id);
    },
    query: function (query) {
      let columns = {
        id: "id",
        nome: "nome",
        cpf: "cpf",
        rg: "rg",
        tipo_profissional_id: "tipo_profissional[nome]",
        conselho: "conselho",
        especialidade_id: "especialidade[especialidade]",
        empresa_id: "empresa[nome_fantasia]",
        tipo_conselho_id: "tipoconselho[conselho]",
        logradouro: "logradouro",
        numero: "numero",
        complemento: "complemento",
        bairro: "bairro",
        cidade: "cidade",
        estado: "estado",
        cep: "cep",
      };
      let filters = "";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>
