<template>
  <div>
    <div class="main-content">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label>Paciente</label>
                <v-select
                  v-model="laudo.paciente_id"
                  :options="pacientes"
                  :reduce="(pacientes) => pacientes.id"
                  :label="'nome_completo'"
                  class="vselect"
                />
              </div>
              <div class="col-md-6">
                <label>Número do Exame</label>
                <input
                  v-model="laudo.exame_id"
                  type="text"
                  class="form-control"
                >
              </div>
              <div class="col-md-6">
                <label>Profissional</label>
                <v-select
                  v-model="laudo.profissional_id"
                  :options="profissionais"
                  :reduce="(profissionais) => profissionais.id"
                  :label="'nome'"
                  class="vselect"
                />
              </div>
              <div class="col-md-6">
                <label>CID</label>
                <v-select
                  v-model="laudo.cid_id"
                  multiple
                  :close-on-select="false"
                  class="vselect"
                  :options="cids"
                  :reduce="(cids) => cids.id"
                  :label="'label_cid'"
                />
              </div>
              <div class="col-md-12">
                <label>Hipótese Diagnóstica</label>
                <textarea
                  cols="30"
                  rows="3"
                  class="form-control"
                  v-model="laudo.hipotese"
                ></textarea>
              </div>
              <div class="col-md-12">
                <label>Observação</label>
                <textarea
                  cols="30"
                  rows="3"
                  class="form-control"
                  v-model="laudo.observacao"
                ></textarea>
              </div>
              <div class="col-md-12 mt-3 text-right">
                <router-link to="/laudos" class="btn btn-back">
                  <i class="fa fa-arrow-left"></i> Voltar
                </router-link>
                <a class="btn btn-add ml-1" @click="save">
                  Salvar
                  <i class="fa fa-save"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
import Multiselect from "vue-multiselect";

export default {
  name: "imageUpload",
  data() {
    return {
      laudo: {
        paciente_id: "",
        exame_id: "",
        profissional_id: "",
        cid_id: "",
        hipotese: "",
        observacao: "",
      },
      pacientes: [],
      profissionais: [],
      cids: [],
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "laudos";

      if (self.laudo.id) {
        api += "/" + self.laudo.id;
        self.laudo._method = "PUT";
      }

      axios
        .post(api, self.laudo)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );
          this.$router.push("/laudos");
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getLaudos: function (id) {
      const self = this;
      const api = self.$store.state.api + "laudos/" + id + "?with[]=cids";

      axios
        .get(api)
        .then((response) => {
          self.laudo = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getPacientes: function () {
      const self = this;
      const api = self.$store.state.api + "pacientes/";

      axios
        .get(api)
        .then((response) => {
          self.pacientes = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getProfissionais: function () {
      const self = this;
      const api = self.$store.state.api + "profissionais/";

      axios
        .get(api)
        .then((response) => {
          self.profissionais = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },

    getCids: function () {
      const self = this;
      const api = self.$store.state.api + "cids/?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.cids = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted: function () {
    const self = this;
    self.getPacientes();
    self.getProfissionais();
    self.getCids();

    let id = self.$route.params.id;
    if (id) {
      self.getLaudos(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
    Multiselect,
  },
};
</script>

<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
