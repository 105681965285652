import Crud from '../views/Prontuario/Crud'
import View from '../views/Prontuario/View'

let routes = {
    path: '/pacientes',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/prontuarios',
            name: 'list-prontuarios',
            component: Crud,
            meta: {
                pageTitle: 'Prontuários',
                breadcrumb: [
                    {
                        text: 'Prontuários',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/prontuarios/view/:id?',
            name: 'ViewProntuario',
            component: View
        },
    ]
};

export default routes
